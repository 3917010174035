import * as React from 'react';
interface IProps {
  color: string;
}
const SettingsIconSvg = (props: IProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill={props.color} {...props}>
    <g fill={props.color || '#9F9F9F'} clipPath='url(#a)'>
      <path d='M12.037 7.35c-2.55 0-4.65 2.1-4.65 4.65a4.65 4.65 0 0 0 4.65 4.65 4.65 4.65 0 0 0 4.65-4.65c0-2.55-2.1-4.65-4.65-4.65Zm0 7.65a2.969 2.969 0 0 1-2.962-2.963 2.945 2.945 0 0 1 2.963-2.962C13.688 9.075 15 10.388 15 12c0 1.65-1.35 3-2.963 3Z' />
      <path d='m22.762 14.287-2.1-1.162c.076-.412.113-.825.113-1.238 0-.45-.037-.862-.075-1.274l2.137-1.2c.488-.263.675-.863.45-1.388L21.114 4.35c-.263-.45-.863-.638-1.35-.45l-2.25 1.237c-.113-.075-.226-.187-.3-.262-.413-.338-.788-.675-1.388-.975-.037 0-.075-.038-.075-.113v-1.35a1.8 1.8 0 0 0-1.8-1.8h-3.862c-.976 0-1.8.825-1.8 1.8V3.9c0 .037-.038.075-.076.112-.562.263-.975.638-1.425.975-.075.075-.187.15-.262.225L4.537 4.087c-.75-.412-1.387-.15-1.65.3L.825 7.95c-.113.3-.15.6-.075.9.075.3.262.525.525.675l2.1 1.125c-.075.375-.075.787-.075 1.275 0 .412.037.825.075 1.237l-2.175 1.2c-.488.263-.675.863-.45 1.388l2.175 3.675c.262.45.825.637 1.35.45l2.25-1.238c.112.075.187.15.3.263.412.375.825.713 1.425.975.037 0 .075.038.075.112V21.6a1.8 1.8 0 0 0 1.8 1.8h3.825c.975 0 1.8-.825 1.8-1.8v-1.725c0-.038.037-.075.075-.113.563-.262.938-.6 1.387-.974.113-.076.188-.15.3-.263L19.5 19.65c.75.413 1.35.15 1.65-.3l2.1-3.563c.15-.262.188-.562.113-.825-.075-.262-.338-.524-.6-.675ZM19.912 18l-1.8-1.013c-.524-.3-1.124-.224-1.574.15-.188.15-.338.263-.488.413-.375.337-.638.563-1.013.712-.6.3-1.012.938-1.012 1.613v1.65c0 .075-.037.113-.113.113h-3.825c-.075 0-.112-.038-.112-.113v-1.612c0-.676-.413-1.313-1.013-1.613-.375-.188-.675-.413-1.05-.75-.15-.113-.3-.262-.487-.375a1.526 1.526 0 0 0-.9-.3c-.225 0-.488.075-.713.188l-1.8 1.012-1.5-2.55 1.763-.975c.525-.3.825-.863.75-1.5-.038-.375-.075-.75-.075-1.125 0-.413.037-.788.075-1.088a1.486 1.486 0 0 0-.75-1.575l-1.65-.937 1.462-2.513 1.8 1.013c.525.3 1.125.225 1.575-.15.15-.113.3-.263.45-.375.375-.338.675-.563 1.05-.75a1.773 1.773 0 0 0 1.013-1.613V2.475c0-.075.037-.113.113-.113h3.825c.075 0 .112.038.112.113V3.9c0 .675.412 1.312 1.012 1.612.375.188.638.413 1.013.713.15.15.3.262.488.412.45.375 1.087.413 1.575.113l1.8-1.013 1.5 2.55-1.726.975c-.524.3-.825.938-.712 1.575.037.338.075.713.075 1.088 0 .375-.038.75-.113 1.125a1.434 1.434 0 0 0 .75 1.537l1.688.938L19.913 18Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill={props.color || '#9F9F9F'} d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default SettingsIconSvg;
