import { useMemo } from 'react';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, TableCell } from '@mui/material';

interface IProps {
  title: string;
  centerTitle?: boolean;
  sortKey: string;
  activeSortKey: string;
  activeSortDirection: string;
  onSort: (value: { sortKey: string; sortDirection: string }) => void;
}

export const SortingTableCell = ({ title, centerTitle, sortKey, activeSortKey, activeSortDirection, onSort }: IProps) => {
  const ascColor = useMemo(() => {
    if (sortKey === activeSortKey && activeSortDirection === 'ASC') return 'black';
    return '#c7c7c7';
  }, [activeSortKey, sortKey, activeSortDirection]);

  const descColor = useMemo(() => {
    if (sortKey === activeSortKey && activeSortDirection === 'DESC') return 'black';
    return '#c7c7c7';
  }, [activeSortKey, sortKey, activeSortDirection]);

  return (
    <TableCell
      sx={{
        fontSize: '15px',
        fontWeight: '500',
        color: '#111928',
      }}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: centerTitle ? 'center' : 'start',
          gap: 2,
        }}>
        {title}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          onClick={() => {
            if (sortKey !== activeSortKey) {
              return onSort({ sortKey, sortDirection: 'ASC' });
            }
            onSort({ sortKey, sortDirection: activeSortDirection === 'ASC' ? 'DESC' : 'ASC' });
          }}>
          <ExpandLess viewBox='0 0 24 14' sx={{ color: ascColor, cursor: 'pointer' }} />
          <ExpandMore viewBox='0 8 24 24' sx={{ color: descColor, cursor: 'pointer' }} />
        </Box>
      </span>
    </TableCell>
  );
};
