import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { isValidCounterUser } from 'store/features/auth-slice';
import { useAppDispatch } from 'store/hooks';

export const VerifyAccount = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getLastElementUrl = (): string => {
    const url = window.location.href;
    const urlParts = url.split('/');

    return urlParts[urlParts.length - 1];
  };

  useEffect(() => {
    dispatch(isValidCounterUser(getLastElementUrl()))
      .unwrap()
      .then((res) => {
        if (res) {
          navigate('/login');
          return res;
        }
      })
      .catch(() => {});
  }, []);

  return <> </>;
};
