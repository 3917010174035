import React from 'react';

import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';

import { useAppLoad, useResetScroll } from '@genaios/hooks';
import { EAuthorized } from '@genaios/types';

import { useAppSelector } from 'store/hooks';

import { AuthorizedRoutes } from './authorized-routes/AuthorizedRoutes';
import { NonAuthorizedRoutes } from './non-authorized-routes/NonAuthorizedRoutes';

export const MainRouter = () => {
  const { isAuthorized, user } = useAppSelector((state) => state.auth);

  useAppLoad();
  useResetScroll();

  if (isAuthorized === EAuthorized.AUTHORIZED && user) {
    return <AuthorizedRoutes />;
  } else if (isAuthorized === EAuthorized.UNAUTHORIZED) {
    return <NonAuthorizedRoutes />;
  } else {
    return (
      <Grid container height='100vh' justifyContent='center' alignItems='center'>
        <CircularProgress />
      </Grid>
    );
  }
};
