import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Autocomplete, ButtonProps, FormControl, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { IPaginationParams, IScanHistoryData } from '@genaios/types';

import { createReport } from 'store/features/report-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getScanHistory } from 'store/services/scan-history.service';

import { EmptyScanHistoryModal } from '../empty-scan-history-modal/EmptyScanHistoryModal';

const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '497px',
  height: '196px',
  bgcolor: '#FFFFFF',
  borderRadius: '6px',
  p: 2,
};

interface IProps extends ButtonProps {
  title: string;
}

export const SelectScanHistoryModal = ({ title, ...props }: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [showEmptyScanHistoryModal, setShowEmptyScanHistoryModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedScanHistoryId, setSelectedScanHistoryId] = useState<string>('');
  const [scanHistoryData, setScanningHistoryData] = useState<IScanHistoryData>();
  const { creating } = useAppSelector((state) => state.report);

  const onCreateReport = () => {
    if (!selectedScanHistoryId) return;

    dispatch(createReport(selectedScanHistoryId))
      ?.unwrap()
      .then((res) => {
        navigate(`/reports/${res?.model?.toLowerCase()}/${res.externId}`);
      })
      .catch(() => {});
  };

  const onOpenModal = () => {
    setShowModal(true);
  };

  const onGetScanHistory = (params: IPaginationParams) => {
    setLoading(true);
    getScanHistory({ page: 1, size: 20, search: params?.search })
      .then((data) => {
        setLoading(false);
        setScanningHistoryData(data);
        if (data?.totalDocuments === 0 && !params?.search) {
          setShowEmptyScanHistoryModal(true);
          setShowModal(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!showModal) return;
    onGetScanHistory({ page: 1, size: 20 });
  }, [showModal]);

  return (
    <>
      <Button {...props} onClick={onOpenModal}>
        {title}
      </Button>

      {showEmptyScanHistoryModal && <EmptyScanHistoryModal onCancel={() => setShowEmptyScanHistoryModal(false)} />}

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box sx={style}>
          <Typography fontSize={16} fontWeight='bold'>
            {t('scanFeatures.selectFile')}
          </Typography>

          <Box mt={2}>
            <Typography fontSize={14} color='#94A3B8'>
              {t('reports.selectToMakeReport')}
            </Typography>
            <FormControl sx={{ width: '100%', marginTop: 1 }} size='small'>
              <Autocomplete
                loading={loading}
                loadingText={t('reports.loading')}
                size='small'
                disablePortal
                renderInput={(params) => <TextField {...params} />}
                onInputChange={(_, value) => {
                  if (!value) {
                    onGetScanHistory({ page: 1, size: 20 });
                  }

                  if (value?.length > 3) {
                    onGetScanHistory({ page: 1, size: 10, search: value });
                  }
                }}
                options={(scanHistoryData?.results || [])?.map((item) => {
                  return { label: item.title, id: item.id };
                })}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  );
                }}
                onChange={(_, value) => {
                  setSelectedScanHistoryId((value as { id: string })?.id);
                }}
              />
            </FormControl>
          </Box>

          <Box mt={2} textAlign='right'>
            <Button onClick={() => setShowModal(false)} sx={{ marginRight: 2 }} variant='outlined'>
              {t('buttons.cancel')}
            </Button>
            <Button onClick={onCreateReport} variant='contained'>
              {creating ? t('reports.creating') : t('reports.createAReport')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
