import React from 'react';

import { Box, LinearProgress } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const TableLoading = () => {
  return (
    <TableRow>
      <TableCell padding={'none'} colSpan={6}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress sx={{ width: '100%' }} />
        </Box>
      </TableCell>
    </TableRow>
  );
};
