import { useTranslation } from 'react-i18next';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';

interface IProps {
  setShowScanDetails: (value: boolean) => void;
  showScanDetails: boolean;
}

export const ViewScanDetailsButton = ({ setShowScanDetails, showScanDetails }: IProps) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => setShowScanDetails(!showScanDetails)}
      startIcon={showScanDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      endIcon={showScanDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      component='label'
      variant='outlined'
      tabIndex={-1}
      fullWidth
      sx={{ height: '48px', mt: '25px', fontWeight: 600, display: 'flex' }}>
      {showScanDetails ? t('buttons.hideDetails') : t('buttons.viewScanDetails')}
    </Button>
  );
};
