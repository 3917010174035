import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Constants } from '@genaios/constants/common';
import { IAnalysePayload } from '@genaios/types';

import { useAnalyzeText } from './AnalyzeTex.hooks';

interface IProps {
  type?: string;
  setScannedText: (value: IAnalysePayload[]) => void;
  setNewScan: (value: boolean) => void;
  setOpenErrorModal: (value: boolean) => void;
}

export const AnalyzeText = ({ setOpenErrorModal, type, setScannedText, setNewScan }: IProps) => {
  const { t } = useTranslation();
  const { textIdentificationData, loading, claimsData, dispatchAction } = useAnalyzeText();

  const { control, handleSubmit } = useForm({
    defaultValues: { search: '' },
  });

  const onSubmit = async ({ search }: { search: string }) => {
    if (loading) return;

    const joinOneRow = search.split('\n').join(' ');
    const splitByWords = joinOneRow.split(' ');

    if (splitByWords.length < 5) {
      setOpenErrorModal(true);
    } else {
      const separateByLine = search
        .split('\n')
        ?.map((text, index) => {
          if (!text?.trim()) return { id: -1, text: '' };
          return { id: index, text };
        })
        ?.filter((item) => item?.text);

      await dispatchAction(separateByLine, type || Constants.AnalyseOptions[0], 1);
      setScannedText(separateByLine);
    }
  };

  useEffect(() => {
    if (textIdentificationData && !Array.isArray(textIdentificationData)) {
      setOpenErrorModal(true);
    } else if (textIdentificationData || claimsData) {
      setNewScan(false);
    }
  }, [textIdentificationData, claimsData]);

  if (loading) return <></>;

  return (
    <div>
      <Box>
        <Controller
          name='search'
          control={control}
          rules={{ required: t('scanFeatures.textFieldRequired') }}
          render={({ field, fieldState }) => (
            <TextField
              value={field.value}
              onChange={field.onChange}
              margin='normal'
              required
              fullWidth
              multiline
              rows={9}
              placeholder={t('scanFeatures.textScanPlaceholder')}
              autoFocus
              error={!!fieldState?.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Box>

      <Box display='flex' justifyContent='flex-end'>
        <Button
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          component='label'
          variant='contained'
          tabIndex={-1}
          sx={{ alignSelf: 'end', mt: '5px' }}>
          {t('buttons.scanText')}
        </Button>
      </Box>
    </div>
  );
};
