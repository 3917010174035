import React, { useState } from 'react';
import { useEffect } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { Box, Button, Grid, IconButton, OutlinedInput, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { Constants } from '@genaios/constants/common';
import { useNebulyInteractions } from '@genaios/hooks/nebuly';
import { useProgressBarInterval } from '@genaios/hooks/useProgressBarInterval';

import { processDocument, resetDocument, setShowScanDetails } from 'store/features/analyze-document-slice';
import { renameUserResult } from 'store/features/scan-history-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AIContentResults } from '../AIContentResults/AIContentResults';
import { FactCheckingResults } from '../FactCheckingResults/FactCheckingResults';
import { LinearProgressBar } from '../LinearProgressBar/LinearProgressBar';
import { Nebuly } from '../Nebuly/Nebuly';
import { ScanningOptionsDropdown } from '../ScanningOptionsDropdown/ScanningOptionsDropdown';

const fileTypes = ['PDF', 'DOCX'];

export const UploadDocument = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { progress } = useProgressBarInterval();

  const [file, setFile] = useState<File>();
  const [type, setType] = useState<string>(Constants.AnalyseOptions[0]);
  const [showNameInput, setShowNameInput] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [changedName, setChangedName] = useState<string | undefined>(undefined);
  const [nameSaved, setNameSaved] = useState<string | undefined>(undefined);

  const { onStartInteractionSessionWithNebuly } = useNebulyInteractions(type, true);

  const { loading, identificationData, uploadPercentage, showScanDetails, claimsData, resultId } = useAppSelector((state) => state.analyzeDocument);

  const handleFileUpload = async (file?: File) => {
    if (!file) return;

    setFile(file);
    setNameSaved('');
    setChangedName(undefined);

    const formData = new FormData();
    formData.append('file', file);

    dispatch(processDocument({ document: formData, page: 0, model: type === Constants.AnalyseOptions[0] ? 'IDENTIFICATION' : 'CLAIM_DETECTION' }));
  };

  const onNameChange = () => {
    if (changedName === undefined) {
      return toast.warning(t('scanHistory.sameDocumentName'));
    }
    if (changedName === '') {
      return toast.warning(t('scanHistory.emptyDocumentName'));
    }

    if (!resultId) return;
    dispatch(
      renameUserResult({
        resultId,
        title: changedName,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(t('scanHistory.renameSuccess'));
        setHovered(false);
        setShowNameInput(false);
        setNameSaved(changedName);
        setChangedName(undefined);
      })
      .catch(() => {
        toast.error(t('scanHistory.renameError'));
      });
  };

  const onNewScan = () => {
    dispatch(resetDocument());
    dispatch(setShowScanDetails(false));
  };

  const handleChangeTypeCheck = (event: SelectChangeEvent<unknown>) => {
    setType(event.target.value as string);
  };

  const handleMouseEnter = () => {
    if (!showNameInput) setHovered(true);
  };

  const handleMouseLeave = () => {
    if (!showNameInput) setHovered(false);
  };

  useEffect(() => {
    if (identificationData) {
      onStartInteractionSessionWithNebuly();
    }
  }, [identificationData]);

  return (
    <>
      {!identificationData && !claimsData && !loading && (
        <ScanningOptionsDropdown
          title={t('scanFeatures.uploadDocument')}
          value={type}
          newScan={!showScanDetails}
          options={Constants.AnalyseOptions}
          onChange={handleChangeTypeCheck}
        />
      )}

      <Box>
        {(identificationData === undefined || identificationData?.length === 0) &&
        (claimsData === undefined || claimsData?.length === 0) &&
        !loading ? (
          <FileUploader classes='file-upload' handleChange={handleFileUpload} name='file' types={fileTypes}>
            <img src={require('assets/images/upload.svg').default} alt='Upload' />

            <Box sx={{ textAlign: 'center' }}>
              <Typography fontWeight={600}>{t('scanFeatures.uploadDocumentTitle')}</Typography>
              <Typography fontWeight={300}>{t('scanFeatures.uploadDocumentDescription')}</Typography>
            </Box>

            <Button component='label' variant='contained' tabIndex={-1}>
              {t('scanFeatures.selectFile')}
            </Button>
          </FileUploader>
        ) : loading ? (
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '280px' }}>
            <LinearProgressBar variant='determinate' value={uploadPercentage || progress * 100} />
          </Box>
        ) : (
          <>
            {!showScanDetails ? (
              <Box
                sx={{
                  borderRadius: '8px',
                  height: '280px',
                  width: '100%',
                  marginTop: '40px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '30px',
                }}>
                <Box sx={{ alignSelf: 'flex-start', width: '100%' }} onMouseEnter={() => handleMouseEnter()} onMouseLeave={handleMouseLeave}>
                  {showNameInput && hovered ? (
                    <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                      <Grid item>
                        <OutlinedInput
                          defaultValue={nameSaved || file?.name}
                          sx={{ width: '300px' }}
                          size='small'
                          onChange={(e) => setChangedName(e.target.value)}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => setShowNameInput(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => onNameChange()}>
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container direction='row' spacing={2} alignItems={'center'}>
                      <Grid item sx={{ fontSize: 22, fontWeight: 600 }}>
                        {nameSaved || file?.name}
                      </Grid>
                      <Grid item>
                        {hovered ? (
                          <Button
                            sx={{ fontSize: '0.8rem', textTransform: 'none', color: '#9f9f9f' }}
                            variant='text'
                            startIcon={<EditCalendarIcon />}
                            onClick={() => setShowNameInput(true)}>
                            {t('scanHistory.editName')}
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  )}
                </Box>

                <FileUploader classes='file-upload' handleChange={handleFileUpload} name='file' types={fileTypes}>
                  <img src={require('assets/images/cloud-check.svg').default} alt='cloud' />
                  <Typography fontWeight={600} fontSize={16} color='#111928'>
                    {t('scanFeatures.uploadDocument')}
                  </Typography>
                </FileUploader>
                <Typography fontWeight={300} fontSize={16}>
                  {nameSaved || file?.name}
                </Typography>
                <Button
                  onClick={() => {
                    dispatch(setShowScanDetails(true));
                    setShowNameInput(false);
                    setChangedName(undefined);
                    setNameSaved(undefined);
                    setHovered(false);
                  }}
                  component='label'
                  variant='contained'
                  tabIndex={-1}
                  sx={{ alignSelf: 'end' }}>
                  {t('scanFeatures.scanDocument')}
                </Button>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                  }}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      padding: '20px 5px 50px',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '8px',
                      border: '1px solid #DFE4EA',
                    }}>
                    <Typography variant='h6' color='#111928' align='left' fontWeight='600' alignSelf='start' sx={{ display: { xs: 'none' } }}>
                      {t('scanFeatures.uploadDocument')}
                    </Typography>

                    <Typography sx={{ fontSize: '22px', fontWeight: '600', alignSelf: 'flex-start', mb: '20px' }}>
                      {t('scanFeatures.scanningResults')}
                    </Typography>

                    {identificationData && identificationData?.length > 0 && <AIContentResults resultsData={identificationData} />}

                    {claimsData && <FactCheckingResults claimsData={claimsData} />}
                  </Box>

                  <Button
                    onClick={() => onNewScan()}
                    component='label'
                    variant='contained'
                    tabIndex={-1}
                    sx={{ backgroundColor: '#111928', alignSelf: { xs: 'start', sm: 'end' }, marginTop: '20px', mb: 0 }}>
                    {t('scanFeatures.newScan')}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      <Nebuly analyseOption={type} isDocumentScan={true} />
    </>
  );
};
