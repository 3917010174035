import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import Container from '@mui/material/Container';

import { SelectScanHistoryModal } from '../select-scan-history-modal/SelectScanHistoryModal';

export const EmptyReports = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container component='main' maxWidth='md' sx={{ textAlign: 'center' }}>
      <img src={require('assets/images/reports.svg').default} alt='reports' />

      <Typography sx={{ color: '#1F1F1F', fontWeight: '700', fontSize: '30px', marginTop: '15px' }}>{t('reports.reports')}</Typography>
      <Typography sx={{ color: '#1F1F1F', fontWeight: '400', fontSize: '18px', marginTop: '15px' }}>{t('reports.reportsDescription')}</Typography>

      <Box flexDirection='column' display='flex' alignItems='center'>
        <Button onClick={() => navigate('/')} size='large' variant='contained' sx={{ marginTop: '65px', width: 210 }}>
          {t('reports.makeScan')}
        </Button>

        <SelectScanHistoryModal size='large' variant='outlined' sx={{ marginTop: '20px', width: 210 }} title={t('reports.createReport')} />
      </Box>
    </Container>
  );
};
