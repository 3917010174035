import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { t } from 'i18next';
import jsPDF from 'jspdf';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Constants } from '@genaios/constants/common';
import { EAuthorized, EReportType, IReport } from '@genaios/types';

import { getReportById } from 'store/features/report-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AIReport } from '../@components/ai-report/AIReport';
import { FactCheckingReport } from '../@components/fact-checking-report/FactCheckingReport';
import { ShareReport } from '../@components/share-report/ShareReport';

export const Report = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const reportTemplateRef = useRef<HTMLDivElement>(null);

  const { reportId, model } = useParams();

  const { isAuthorized } = useAppSelector((state) => state.auth);

  const [showShareModal, setShowShareModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<IReport>();

  const isPublicView = useMemo(() => {
    return location?.pathname?.includes('public/report') && isAuthorized !== EAuthorized.AUTHORIZED;
  }, [location]);

  useEffect(() => {
    if (!reportId) return;

    dispatch(getReportById(reportId))
      ?.unwrap()
      ?.then((report) => {
        setSelectedReport(report?.report);
      })
      .catch(() => {});
  }, [reportId]);

  const handleGeneratePdf = async () => {
    if (!reportTemplateRef.current) return;

    const originalStyle = reportTemplateRef.current.style.cssText;

    reportTemplateRef.current.style.height = 'auto';
    reportTemplateRef.current.style.maxHeight = 'none';
    reportTemplateRef.current.style.overflow = 'visible';

    try {
      const canvas = await html2canvas(reportTemplateRef.current, {
        useCORS: true,
        scale: 1.5, // Reduced scale for better performance
        logging: true,
        scrollY: 0,
      });

      const imgData = canvas.toDataURL('image/jpeg', 0.8); // Compress image by reducing quality (80%)

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4',
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pdfHeight;
      }

      pdf.save('report.pdf');
    } catch {
      // catch errors here
    } finally {
      reportTemplateRef.current.style.cssText = originalStyle;
    }
  };

  return (
    <Box>
      {!isPublicView ? (
        <Grid display='flex' justifyContent='space-between' sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
          <Grid item>
            <Typography
              sx={{ flex: '1 1 100%', fontSize: '22px', fontWeight: '600', pb: '10px', whiteSpace: { xs: 'nowrap', sm: 'normal' } }}
              variant='h6'
              id='tableTitle'
              component='div'>
              Report view
            </Typography>
          </Grid>

          <Grid item sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'space-between', sm: 'normal' } }}>
              <Button
                sx={{ width: { xs: '47%', sm: 'unset' }, background: 'white', color: 'black' }}
                onClick={() => navigate('/reports')}
                variant='contained'>
                {t('buttons.close')}
              </Button>

              <Button
                sx={{ width: { xs: '47%', sm: 'unset' }, marginLeft: { sm: '10px' } }}
                onClick={() => setShowShareModal(true)}
                variant='contained'>
                {t('buttons.share')}
              </Button>
            </Box>

            <Button
              sx={{
                width: { xs: '100%', sm: 'unset' },
                marginLeft: { sm: '10px' },
                marginTop: { xs: '10px', sm: 0 },
                marginBottom: { xs: '10px', sm: 0 },
                backgroundColor: '#E7FFD3',
                color: '#1F1F1F',
              }}
              onClick={handleGeneratePdf}
              variant='contained'>
              {t('buttons.downloadPdf')}
            </Button>

            {showShareModal && selectedReport && <ShareReport report={selectedReport} onCancel={() => setShowShareModal(false)} />}
          </Grid>
        </Grid>
      ) : (
        <Grid m={3} display='flex' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ flex: '1 1 100%', fontSize: '22px', fontWeight: '600', pb: '10px' }} variant='h6' id='tableTitle' component='div'>
              Report view
            </Typography>
          </Grid>

          <Grid item>
            <Button
              onClick={() => window.open(`${window.location.origin}/signup`, '_blank')}
              variant='contained'
              style={{ background: 'white', color: 'black' }}>
              Create an account
            </Button>

            <Button onClick={() => window.open(Constants.ChromeExtension.url, '_blank')} style={{ marginLeft: '10px' }} variant='contained'>
              Download plugin
            </Button>

            <Button onClick={handleGeneratePdf} style={{ marginLeft: '10px', backgroundColor: '#E7FFD3', color: '#1F1F1F' }} variant='contained'>
              Download PDF
            </Button>
          </Grid>
        </Grid>
      )}

      <div
        ref={reportTemplateRef}
        style={{
          minHeight: isPublicView ? '100vh' : '75vh',
          maxHeight: isPublicView ? '100vh' : '75vh',
          justifyContent: 'center',
          display: 'flex',
          overflow: 'auto',
          background: '#F3F4F6',
        }}>
        {model?.toLowerCase() === EReportType.IDENTIFICATION ? <AIReport /> : <FactCheckingReport />}
      </div>
    </Box>
  );
};
