export const Constants = {
  LocalStorage: {
    ACCESS_TOKEN: 'access_token',
  },
  AnalyseOptions: ['AI content', 'Claim and Fact checking'],
  FeedbackForm: {
    url: 'https://docs.google.com/forms/d/e/1FAIpQLScAHzYuSZjK-WEBnWKHPBZBhtInvCTyOmx3uqN38XIqAtdXHQ/viewform',
  },
  Analyze: {
    MaxTextLengthAnalyze: 5000,
    MinWordsAnalyze: 20,
    GeneratedAILowerThreshold: 66.7,
    GeneratedAIUpperThreshold: 68.4,
  },
  Nebuly: {
    Token: process.env.REACT_APP_NEBULY_TOKEN,
  },
  ChromeExtension: {
    url: 'https://chromewebstore.google.com/detail/genaios/mojmfndakphphinkoipadfghpahoppfe',
  },
  PrivacyPolicy: {
    url: 'https://genaios.ai/privacy-policy/',
  },
  HelpCenter: {
    url: 'https://genaios.tawk.help/',
  },
};
