import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import AddFilesSvgIcon from 'assets/svg-tsx/add-files';
import FilesIconSvg from 'assets/svg-tsx/files-icon';
import ReportsIconSvg from 'assets/svg-tsx/reports-icon';
import SettingsIconSvg from 'assets/svg-tsx/settings-icon';
import TimeIconSvg from 'assets/svg-tsx/time-icon';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Container, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { logout } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Constants } from '../constants/common';

import { Footer } from './Footer';

const drawerWidth = 263;

const Layout = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { user } = useAppSelector((state) => state.auth);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const onLogout = () => {
    dispatch(logout());
    googleLogout();
  };

  const matches = useMediaQuery('(min-width:600px)');

  const topMenuItems = [
    { icon: AddFilesSvgIcon, text: t('layout.scanFeatures'), path: '/' },
    { icon: TimeIconSvg, text: t('layout.scanHistory'), path: '/scan-history' },
    { icon: ReportsIconSvg, text: t('layout.reports'), path: '/reports' },
    { icon: FilesIconSvg, text: t('layout.documentManager'), path: '/document-manager' },
    { icon: SettingsIconSvg, text: t('layout.accountSettings'), path: '/account-settings' },
  ];

  const bottomMenuItems = [
    {
      icon: <QuestionMarkIcon />,
      text: t('layout.helpCenter'),
      action: () => window.open(Constants.HelpCenter.url, '_blank'),
      bgColor: '#cfcfcf',
    },
    matches
      ? {
          icon: <img src={require('assets/images/download.svg').default} alt='error'></img>,
          text: t('layout.downloadPlugin'),
          action: () => window.open(Constants.ChromeExtension.url, '_blank'),
          bgColor: '#E7FFD3',
        }
      : null,
    {
      icon: <img src={require('assets/images/logout.svg').default} alt='error'></img>,
      text: t('layout.logout'),
      action: onLogout,
      bgColor: '#FFDBDB',
    },
  ];

  const topMenu = (
    <List sx={{ marginLeft: '20px', marginTop: '25px', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <img
          style={{ width: '146px', height: '40px', marginBottom: '30px' }}
          src={require('assets/images/genaios-logo-black.svg').default}
          alt='Genaios'
        />
      </Box>

      <Button onClick={handleDrawerToggle} sx={{ alignSelf: 'flex-end', display: { xs: 'block', md: 'none' } }}>
        <img width={24} src={require('assets/images/close.svg').default} alt='menu' />
      </Button>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '20px' }}>
        <img src={require('assets/images/user.svg').default} alt='userlogo' />
        <Typography sx={{ color: '#1F1F1F', fontWeight: '600', textOverflow: 'ellipsis', overflow: 'hidden' }}>{user?.firstname}</Typography>
      </Box>

      {topMenuItems.map((item, index) => (
        <ListItem
          key={index}
          disablePadding
          sx={{
            width: { xs: `calc(100% - 30px)`, md: '211px' },
            height: '56px',
          }}>
          <NavLink
            to={item?.path}
            onClick={handleDrawerToggle}
            style={({ isActive, isTransitioning }) => {
              return {
                fontWeight: isActive ? 'bold' : '',
                color: isActive ? 'white' : '#9F9F9F',
                backgroundColor: isActive ? '#1F1F1F' : 'transparent',
                textDecoration: 'none',
                viewTransitionName: isTransitioning ? 'slide' : '',
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                width: '100%',
                borderRadius: '12px',
              };
            }}>
            {({ isActive }) => (
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: '34px' }}>
                  <item.icon color={isActive ? '#ffffff' : ''} />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            )}
          </NavLink>
        </ListItem>
      ))}
    </List>
  );

  const bottomMenu = (
    <List sx={{ marginLeft: '20px', marginBottom: '70px', width: { xs: `calc(100% - 50px)`, md: '210px' } }}>
      {bottomMenuItems.map((item, index) => {
        if (item) {
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={item?.action}
                sx={{ backgroundColor: item.bgColor, marginBottom: '10px', borderRadius: '16px', width: '210px', height: '46px' }}>
                <ListItemIcon sx={{ minWidth: '34px' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          );
        }
      })}
    </List>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar sx={{ backgroundColor: '#F3F4F6', display: { xs: 'flex', md: 'none' } }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
          <img style={{ width: '146px', height: '40px' }} src={require('assets/images/genaios-logo-black.svg').default} alt='Genaios' />

          <Button onClick={handleDrawerToggle}>
            <img width={24} src={require('assets/images/menu.svg').default} alt='menu' />
          </Button>
        </Box>
      </AppBar>

      <Box component='nav' sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
              backgroundColor: '#F3F4F6',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
          }}>
          {topMenu}
          {bottomMenu}
        </Drawer>

        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#F3F4F6',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
          }}
          open>
          {topMenu}
          {bottomMenu}
        </Drawer>
      </Box>

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          pb: 0,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          height: '100vh',
          minHeight: '100vh',
          maxHeight: '100vh',
          overflowY: 'auto',
        }}>
        <Box
          component='main'
          sx={{
            backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'white' : theme.palette.grey[900]),
            flexGrow: 1,
          }}>
          <Toolbar />
          <Container sx={{ mt: 4, mb: 6 }}>{children}</Container>
          <Footer drawerWidth={drawerWidth} />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
