import * as React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PasswordField } from '@genaios/components/PasswordField/PasswordField';
import { useGenaiosGoogleLogin } from '@genaios/hooks';
import { ELoadingState } from '@genaios/types';

import { login } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loginState } = useAppSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = useState('');
  const [googleUser, setGoogleUser] = useState<{ access_token?: string; token_type?: string }>({});

  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      username: '',
      password: '',
      rememberMe: false,
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (response) => setGoogleUser(response),
  });

  useGenaiosGoogleLogin(googleUser);

  const onSubmit = ({ username, password }: { username: string; password: string }) => {
    dispatch(login({ username, password }))
      ?.unwrap()
      .then((res) => {
        if (res?.email_not_verified) {
          setErrorMessage(t('authentication.notConfirmedAccount'));
        }
      })
      .catch((error) => {
        if (error?.status === 404) {
          setErrorMessage(t('authentication.pleaseRegister'));
        } else {
          setErrorMessage(t('authentication.wrongEmailOrPassword'));

          setError('username', { type: 'manual' });
          setError('password', { type: 'manual' });
        }
      });
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src={require('assets/images/genaios-logo-black.svg').default} width='250' height='70' alt='Genaios' />

        <Typography fontWeight='bold' sx={{ mt: 5 }} variant='h4'>
          {t('authentication.welcome')}
        </Typography>
        <Typography textAlign='center' color='#667085' sx={{ mt: 1, mb: 3, color: errorMessage ? 'red' : undefined }} variant='subtitle1'>
          {errorMessage ? errorMessage : t('authentication.loginToAccount')}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box component='div'>
            <Controller
              name='username'
              control={control}
              rules={{ required: t('authentication.usernameFieldRequired') }}
              render={({ field, fieldState }) => (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  margin='normal'
                  required
                  fullWidth
                  label={t('authentication.enterEmail')}
                  autoComplete='email'
                  autoFocus
                  error={!!fieldState?.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Controller
              name='password'
              control={control}
              rules={{ required: t('authentication.passwordFieldRequired') }}
              render={({ field, fieldState }) => (
                <PasswordField
                  value={field.value}
                  onChange={field.onChange}
                  margin='normal'
                  fullWidth
                  label={t('authentication.password')}
                  autoComplete='current-password'
                  error={!!fieldState?.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <Grid container alignItems='center'>
              <Grid xs>
                <Controller
                  name='rememberMe'
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      value={field.value}
                      onChange={field.onChange}
                      control={<Checkbox value='remember' color='primary' />}
                      label={t('authentication.rememberMe')}
                    />
                  )}
                />
              </Grid>

              <Grid xs textAlign='right'>
                <Typography onClick={() => navigate('/forgot-password')} sx={{ ml: 1, cursor: 'pointer' }} variant='body2' fontWeight='bold'>
                  {t('authentication.forgotPassword')}
                </Typography>
              </Grid>
            </Grid>

            <Box>
              <Button
                fullWidth
                variant='contained'
                type='submit'
                disabled={loginState === ELoadingState.LOADING}
                onClick={handleSubmit(onSubmit)}
                sx={{ mt: 3, backgroundColor: 'black', borderRadius: 2 }}>
                {loginState === ELoadingState.LOADING ? t('authentication.signingIn') : t('authentication.signIn')}
              </Button>
              <Button fullWidth variant='outlined' sx={{ mt: 3, mb: 2, borderRadius: 2 }} onClick={() => googleLogin()}>
                <img src={require('assets/images/icon-google.svg').default} alt='Google' />
                <Typography marginLeft={'5px'}>{t('authentication.signWithGoogle')}</Typography>
              </Button>
            </Box>

            <Grid container alignItems='center' justifyContent='center'>
              <Typography color='#667085' flexDirection='row' variant='subtitle1'>
                {t('authentication.dontHaveAccount')}
              </Typography>
              <Typography onClick={() => navigate('/signup')} sx={{ ml: 1, cursor: 'pointer' }} variant='subtitle1' fontWeight='bold'>
                {t('authentication.registerNow')}
              </Typography>
            </Grid>
          </Box>
        </form>
      </Box>
    </Container>
  );
};
