import { Constants } from '@genaios/constants/common';
import { IAnalysePayload } from '@genaios/types';

import { analyseFactChecking, analyseTextIdentification, detectClaims } from 'store/features/analyze-text-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useAnalyzeText = () => {
  const dispatch = useAppDispatch();

  const { loading, textIdentificationData, factCheckingData, claimsData } = useAppSelector((state) => state.analyzeText);

  const dispatchAction = async (search: IAnalysePayload[], type: string, step: number) => {
    switch (type) {
      case Constants.AnalyseOptions[0]:
        await dispatch(analyseTextIdentification(search));
        break;
      case Constants.AnalyseOptions[1]:
        if (step === 1) {
          await dispatch(detectClaims(search));
        } else {
          const factCheckingResult = await dispatch(analyseFactChecking(search)).unwrap();
          return factCheckingResult;
        }
        break;
      default:
        await dispatch(analyseTextIdentification(search));
        break;
    }
  };

  return { loading, textIdentificationData, factCheckingData, claimsData, dispatchAction };
};
