import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AccountSettings from 'pages/account-settings/AccountSettings';
import { Home } from 'pages/dashboard/Home';
import DocumentManager from 'pages/document-manager/DocumentManager';
import { Report } from 'pages/reports/report/Report';
import Reports from 'pages/reports/Reports';
import ScanHistory from 'pages/scan-history/ScanHistory';

import Layout from '@genaios/layout/Layout';

export const AuthorizedRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/scan-history' element={<ScanHistory />} />
        <Route path='/reports' element={<Reports />} />
        <Route path='/document-manager' element={<DocumentManager />} />
        <Route path='/account-settings' element={<AccountSettings />} />
        <Route path='/reports/:model/:reportId' element={<Report />} />
        <Route path='public/reports/:model/:reportId' element={<Report />} />

        <Route path='*' element={<Navigate to={`/`} />} />
      </Routes>
    </Layout>
  );
};
