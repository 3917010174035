import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { ELoadingState } from '@genaios/types';

import { forgotPassword } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { control, handleSubmit } = useForm({
    defaultValues: { username: '' },
  });

  const { forgotPasswordState } = useAppSelector((state) => state.auth);

  const onSubmit = ({ username }: { username: string }) => {
    dispatch(forgotPassword({ username }))
      .unwrap()
      .then(() => {
        toast.success(t('authentication.resentPasswordLink'));
        navigate('/login');
      })
      .catch();
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src={require('assets/images/genaios-logo-black.svg').default} width='250' height='70' alt='Genaios' />

        <Typography fontWeight='bold' sx={{ mt: 5 }} variant='h4'>
          {t('authentication.resetPassword')}
        </Typography>
        <Typography color='#667085' sx={{ mt: 1, mb: 3 }} variant='subtitle1'>
          {t('authentication.sendResetPassword')}
        </Typography>

        <Grid sx={{ width: '100%' }}>
          <Controller
            name='username'
            control={control}
            rules={{ required: t('authentication.usernameFieldRequired') }}
            render={({ field, fieldState }) => (
              <TextField
                value={field.value}
                onChange={field.onChange}
                margin='normal'
                required
                fullWidth
                label={t('authentication.emailAddress')}
                autoComplete='email'
                autoFocus
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Box>
            <Button
              fullWidth
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              disabled={forgotPasswordState === ELoadingState.LOADING}
              sx={{ mt: 2, backgroundColor: 'black', borderRadius: 2 }}>
              {forgotPasswordState === ELoadingState.LOADING ? t('authentication.sendingLink') : t('authentication.resetPassword')}
            </Button>
          </Box>

          {/*TODO refactor this part*/}
          <Grid mt={1} container alignItems='center' justifyContent='center'>
            <Typography color='#667085' flexDirection='row' variant='subtitle1'>
              {t('buttons.backTo')}
            </Typography>
            <Typography onClick={() => navigate('/signin')} sx={{ ml: 1, cursor: 'pointer' }} variant='subtitle1' fontWeight='bold'>
              {t('authentication.login')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
