import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Grid, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AIGeneratedCard } from '@genaios/components/GeneratedResultsCards/AIGeneratedCard';
import { HumanGeneratedCard } from '@genaios/components/GeneratedResultsCards/HumanGeneratedCard';
import { IPrediction } from '@genaios/types';
import { getResultAccordingPercentage, isHumanGenerated } from '@genaios/utils/helper';

import { useAppSelector } from 'store/hooks';

import { ReportHeader } from '../report-header/ReportHeader';

export const AIReport = () => {
  const { t } = useTranslation();
  const [parsedResult, setParsedResult] = useState<{ text: { bgColor: string; text: string }[]; result: { predictions: IPrediction[] }[] }>();

  const { report } = useAppSelector((state) => state.report);

  const generatedProperties = useMemo(() => {
    if (!parsedResult) return { human: 0, ai: 0 };

    const data = Array.isArray(parsedResult?.result)
      ? parsedResult?.result
      : (parsedResult?.result as { data: { predictions: IPrediction[] }[] })?.data;

    const total = data?.length;
    let human = 0;

    data?.forEach((result) => {
      if (isHumanGenerated(result)) {
        human++;
      }
    });

    const humanGenerated = (human / total) * 100;
    return { human: humanGenerated?.toFixed(2), ai: (100 - humanGenerated)?.toFixed(2) };
  }, [parsedResult?.result]);

  const onNavigateToSource = () => {
    if (!report?.result?.url) return;

    window.open(report?.result?.url, '_blank');
  };

  useEffect(() => {
    if (!report?.result) return;

    const parsedText = JSON.parse(report?.result?.text);
    const parsedResult: { data: { predictions: IPrediction[] }[] } = JSON.parse(report?.result?.result);

    setParsedResult({
      text: parsedText?.map((item: { text: number }, index: number) => {
        const generatedPercent = parsedResult?.data?.[index]?.predictions?.find((prediction) => prediction.generated !== undefined)?.generated?.value;

        return {
          ...item,
          ...getResultAccordingPercentage(generatedPercent),
        };
      }),
      result: parsedResult?.data,
    });
  }, [report]);

  return (
    <div style={{ background: 'white', width: '80%', padding: '20px', height: '100%', minHeight: '80vh' }}>
      <ReportHeader />
      <Grid mt={2} ml={2}>
        <Typography variant='subtitle1'>Scan source</Typography>
        <Typography color='#9F9F9F' variant='subtitle2'>
          This result comes from the scanning of:
        </Typography>
        <div onClick={() => onNavigateToSource()}>
          <Typography style={{ cursor: 'pointer' }} variant='subtitle1' mt={1}>
            <DescriptionOutlinedIcon style={{ marginBottom: '4px' }} /> {report?.report?.name}
          </Typography>
        </div>
      </Grid>
      <Box mt={3} sx={{ display: 'flex', gap: '20px', width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
        <HumanGeneratedCard percentage={generatedProperties?.human} />
        <AIGeneratedCard percentage={generatedProperties?.ai} />
      </Box>

      <Grid mt={2}>
        <Paper style={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant='subtitle1' fontWeight='bold'>
            {t('reports.aiProbabilities')}
          </Typography>
          <Typography color='#9F9F9F' variant='subtitle2'>
            {t('reports.largeLanguageModels')}
          </Typography>
        </Paper>
      </Grid>

      <Grid mt={2} container spacing={2}>
        <Grid item md={6}>
          <Paper style={{ textAlign: 'center', padding: '20px' }}>
            <Typography color='#9F9F9F' variant='subtitle2'>
              {t('reports.highlightedTextByHuman')}
            </Typography>
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper style={{ textAlign: 'center', padding: '20px' }}>
            <Typography color='#9F9F9F' variant='subtitle2'>
              {t('reports.highlightedTextByAi')}
              <br /> <br />
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box
        sx={{
          border: '1px solid #DFE4EA',
          padding: '10px',
          borderRadius: '8px',
          marginTop: '15px',
          width: '100%',
        }}>
        {parsedResult?.text?.map((result, index: number) => (
          <Typography
            key={index}
            fontWeight={400}
            fontSize={16}
            marginTop={1}
            color={'#9CA3AF'}
            sx={{
              width: '100%',
              backgroundColor: result?.bgColor,
            }}>
            {result?.text}
          </Typography>
        ))}
      </Box>
    </div>
  );
};
