import axios from 'axios';

import { Constants } from '@genaios/constants/common';

import { IInteractionSessionWithNebulyPayload } from '../../@genaios/types';

export const startInteractionSessionWithNebuly = async (body: IInteractionSessionWithNebulyPayload): Promise<void> =>
  axios.post('https://backend.nebuly.com/event-ingestion/api/v1/events/interactions', body, {
    headers: { Authorization: `Bearer ${Constants.Nebuly.Token}` },
  });
