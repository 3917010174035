import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { de, en, sp } from './languages';

export type ITranslateKeys = 'en' | 'sp' | 'de';

export const resources = {
  en: {
    translation: en,
  },
  sp: {
    translation: sp,
  },
  de: {
    translation: de,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: true,
  },
});

export default i18n;
