import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { DeleteModalProps, ELoadingState } from '@genaios/types';

import { useAppSelector } from 'store/hooks';

const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '497px',
  height: '296px',
  bgcolor: '#FFFFFF',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  p: 2,
};

export const DeleteUserModal = ({ open, onCancel, onSave }: Omit<DeleteModalProps, 'isBulkDelete'>) => {
  const { t } = useTranslation();
  const { deleting } = useAppSelector((state) => state.auth);

  return (
    <Modal open={open} onClose={onCancel} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <img src={require('assets/images/error-icon.svg').default} alt='error' style={{ marginTop: '10px' }}></img>
        <Typography
          id='modal-modal-title'
          variant='h1'
          component='h1'
          sx={{ width: '600', fontFamily: 'Inter', fontSize: '24px', fontWeight: '600', marginTop: '10px' }}>
          {t('accountSettings.deleteYourAccount')}
        </Typography>
        <Typography id='modal-modal-description' sx={{ color: '#9F9F9F', width: '400', fontSize: '16px', textAlign: 'center', marginTop: '10px' }}>
          {t('accountSettings.deleteWarning')}
          <br></br>
          {t('accountSettings.deleteConfirmation')}
        </Typography>

        <Box sx={{ marginTop: '14px' }}>
          <Button
            onClick={onCancel}
            disabled={deleting === ELoadingState.LOADING}
            sx={{
              backgroundColor: '#FFFFFF',
              border: '1px solid #CFCFCF',
              color: '#1F1F1F',
              width: '190px',
              height: '50px',
              textTransform: 'none',
              alignItems: 'center',
              margin: '10px',
              borderRadius: '6px',
            }}>
            {t('buttons.cancel')}
          </Button>

          <Button
            onClick={onSave}
            disabled={deleting === ELoadingState.LOADING}
            sx={{
              '&:hover': {
                backgroundColor: '#C10606',
                color: '#fff',
              },
              backgroundColor: '#C10606',
              color: '#fff',
              width: '190px',
              height: '50px',
              textTransform: 'none',
              alignItems: 'center',
              margin: '10px',
              borderRadius: '6px',
            }}>
            {deleting === ELoadingState.LOADING ? t('buttons.deleting') : t('buttons.delete')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
