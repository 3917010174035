import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { ErrorModalProps } from '@genaios/types';

const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '530px',
  height: '360px',
  bgcolor: '#FFFFFF',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  p: 4,
};

export const ErrorModal = ({ open, handleClose }: ErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <img src={require('assets/images/error-icon.svg').default} alt='error'></img>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            sx={{ width: '600', fontFamily: 'Inter', fontSize: '24px', marginTop: '20px' }}>
            {t('common.somethingWentWrong')}
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 2, color: '#9F9F9F', width: '400', fontSize: '16px', textAlign: 'center', marginTop: '20px' }}>
            {t('common.analysisText')}
            <br></br> {t('common.addMoreContent')}
          </Typography>
          <Button
            onClick={handleClose}
            sx={{
              '&:hover': {
                backgroundColor: '#C10606',
                color: '#fff',
              },
              backgroundColor: '#C10606',
              color: '#fff',
              width: '430px',
              height: '50px',
              textTransform: 'none',
              alignItems: 'center',
              margin: '25px',
              borderRadius: '6px',
            }}>
            {t('buttons.tryAgain')}
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
