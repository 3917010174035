import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '497px',
  height: '296px',
  bgcolor: '#FFFFFF',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  p: 2,
};

export const EmptyScanHistoryModal = ({ onCancel }: { onCancel: () => void }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal open={true} onClose={onCancel} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <img src={require('assets/images/error-icon.svg').default} alt='error' style={{ marginTop: '10px' }}></img>
        <Typography
          id='modal-modal-title'
          variant='h6'
          component='h2'
          sx={{ width: '600', fontFamily: 'Inter', fontSize: '24px', marginTop: '10px' }}>
          {t('reports.couldNotGenerateReports')}
        </Typography>
        <Typography id='modal-modal-description' sx={{ color: '#9F9F9F', width: '400', fontSize: '16px', textAlign: 'center', marginTop: '10px' }}>
          {t('reports.notScannedReport')}
        </Typography>

        <Box sx={{ marginTop: '10px' }}>
          <Button
            onClick={() => navigate('/')}
            sx={{
              backgroundColor: '#FFFFFF',
              border: '1px solid #CFCFCF',
              color: '#1F1F1F',
              width: '190px',
              height: '50px',
              textTransform: 'none',
              alignItems: 'center',
              margin: '10px',
              borderRadius: '6px',
            }}>
            {t('reports.makeAScan')}
          </Button>

          <Button
            onClick={onCancel}
            sx={{
              '&:hover': {
                backgroundColor: '#C10606',
                color: '#fff',
              },
              backgroundColor: '#C10606',
              color: '#fff',
              width: '190px',
              height: '50px',
              textTransform: 'none',
              alignItems: 'center',
              margin: '10px',
              borderRadius: '6px',
            }}>
            {t('buttons.close')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
