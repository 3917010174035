/* eslint-disable  @typescript-eslint/no-explicit-any */
import i18n from 'translation/i18n';

import { IPrediction } from '../types/analyze-text';

export const arrayToObject = (array: any[], keyField = 'id') =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});

export const objectToArray = <T>(object: T) => Object.values(object || {}).map((value) => value);

export const groupDuplicatesByPropertyName = <T>(array: T[], propertyName: keyof T) => {
  const groupedData: { [key: string]: T[] } = {};

  array.forEach((obj) => {
    const key = obj[propertyName];

    if (!groupedData[key as string]) {
      groupedData[key as string] = [];
    }
    groupedData[key as string].push(obj);
  });

  return groupedData;
};

export const extractQueryParams = <T>(search: string, dry = false): T => {
  if (!search) return {} as T;

  return search
    .replace('?', '')
    .split('&')
    .reduce((data: T, item) => {
      const [k, v] = item.split('=');

      if (!k || !v) return data;

      if (dry) return { ...data, [k]: v };

      if (v.includes(',')) return { ...data, [k]: v.split(',') };

      if (['true', 'false'].includes(v)) return { ...data, [k]: v === 'true' };

      if (v === 'null') return { ...data, [k]: null };

      if (!Number.isNaN(+v)) return { ...data, [k]: +v };

      return { ...data, [k]: v };
    }, {} as T);
};

export const delay = (delayTime = 300): Promise<void> => new Promise((resolve) => setTimeout(resolve, delayTime));

export const parse = <T>(value: string | undefined, def: T): T => {
  if (!value) return def;
  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return def;
  }
};

export const searchInObj = <T>(itemObj: T, searchText: string) => {
  for (const prop in itemObj) {
    if (!Object.prototype.hasOwnProperty.call(itemObj, prop)) {
      continue;
    }

    const value = itemObj[prop];

    if (typeof value === 'string') {
      if (searchInString(value, searchText)) {
        return true;
      }
    } else if (Array.isArray(value)) {
      if (searchInArray(value, searchText)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (searchInObj(value, searchText)) {
        return true;
      }
    }
  }
};

export const searchInString = (value: string, searchText: string) => {
  return value.toLowerCase().includes(searchText);
};

export const filterArrayByString = <T>(mainArr: T[], searchText: string) => {
  if (searchText === '') {
    return mainArr;
  }
  searchText = searchText.toLowerCase();

  return mainArr.filter((itemObj) => {
    return searchInObj(itemObj, searchText);
  });
};

export const searchInArray = <T>(arr: T[], searchText: string) => {
  if (!arr.length) return false;
  for (const value of arr) {
    if (typeof value === 'string') {
      if (searchInString(value, searchText)) {
        return true;
      }
    }

    if (typeof value === 'object') {
      if (searchInObj(value, searchText)) {
        return true;
      }
    }
  }
};

export const removeDuplicates = <T>(arr: T[], key: keyof T) => {
  return arr.reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj[key] === o[key])) {
      unique.push(o);
    }
    return unique;
  }, []);
};

export const iterateObject = <T>(array: T[], id: string | number) => {
  for (const k in array) {
    const obj = array[k];
    const foundObj = findObjectById(obj, id);
    if (foundObj) {
      return foundObj;
    }
  }
};

export const findObjectById = (root: any, id: string | number) => {
  if (root.id === id) {
    return root;
  }
  if (root.children && root.children.length > 0) {
    for (const k in root.children) {
      if (root.children[k].id === id) {
        return root.children[k];
      } else if (root.children[k].children && root.children[k].children.length > 0) {
        const result: any = findObjectById(root.children[k], id);
        if (result) {
          return result;
        }
      }
    }
  }
};
export const findItemsInArray = <T>(items: T[], key: keyof T, value: string) => {
  return items?.find((item) => item[key] === value);
};

export const removeDuplicatesByMap = (array: any[], key: string) => {
  return [...Array.from(new Map(array.map((arr) => arr?.[key]).map((item) => [item?.[key], item])).values())];
};

export const getOfficeIdFromUrl = (url: string) => {
  const regexp = /\/offices\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/gi;
  const matches = regexp.exec(url);

  return matches && matches.length > 1 ? matches[1] : null;
};

export const toHumanReadable = (str?: string) => {
  if (!str) return '';
  return str
    .replace(/_/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .replace(/(^|\s)\S/g, function (t) {
      return t.toUpperCase();
    });
};

export const camelToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const normalToSnakeCase = (str: string) => {
  return str.replace(/\s/g, '_').toLowerCase();
};

export const snakeCaseToNormal = (str: string) => {
  return str.replace(/([-_][a-z])/gi, ($1) => {
    return $1.replace('-', ' ').replace('_', ' ');
  });
};

export const toCapitalize = (str: string | undefined) => {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const randomFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const downloadCSVFile = (data: any, fileName: string) => {
  const blob = new Blob([data]);
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;

  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const convertNestedJSONtoObject = (inputStr: string): any => {
  try {
    const jsonData = JSON.parse(inputStr);

    if (typeof jsonData === 'object') {
      for (const key in jsonData) {
        if (Object.prototype.hasOwnProperty.call(jsonData, key)) {
          jsonData[key] = convertNestedJSONtoObject(jsonData[key]);
        }
      }
    }

    return jsonData;
  } catch (error) {
    return inputStr;
  }
};

export const compareJSONObjects = (newJSON: any, oldJSON: any, parentKey = ''): { path: string; oldValue: string; newValue: string }[] => {
  const differences: { path: string; oldValue: string; newValue: string }[] = [];
  const newValue = convertNestedJSONtoObject(newJSON);
  const oldValue = convertNestedJSONtoObject(oldJSON);

  for (const key in newValue) {
    const fullPath = parentKey ? `${parentKey}.${key}` : key;

    const readablePath = fullPath

      .replace(/[\\[\].0-9]/g, '-')
      .split('----')
      .join('-');

    if (typeof newValue[key] === 'object' && typeof oldValue[key] === 'object') {
      if (Array.isArray(newValue[key]) && Array.isArray(oldValue[key])) {
        // Compare arrays element by element
        for (let i = 0; i < newValue[key].length; i++) {
          differences.push(...compareJSONObjects(newValue[key][i], oldValue[key][i], `${fullPath}[${i}]`));
        }
      } else {
        differences.push(...compareJSONObjects(newValue[key], oldValue[key], fullPath));
      }
    } else {
      if (newValue[key] !== oldValue[key]) {
        differences.push({
          path: readablePath,
          newValue: newValue[key],
          oldValue: oldValue[key],
        });
      }
    }
  }

  return differences;
};

export const isHumanGenerated = (item: { predictions?: IPrediction[] }) => {
  return !item?.predictions?.find((prediction) => prediction.generated !== undefined)?.generated?.value;
};

export const getResultAccordingPercentage = (percentage = 0) => {
  if (percentage > 0) {
    return {
      infoText: i18n.t('common.textWrittenByAi'),
      description: i18n.t('common.largeLanguageModelFamily'),
      bgColor: '#FBC0C0',
    };
  }

  return {
    infoText: i18n.t('common.writtenByHuman'),
    description: i18n.t('common.largeLanguageModelsByHuman'),
    bgColor: '#ACEFC8',
  };
};
