import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IFiltersParams, IPaginationParams, IReportState, ISortingParams } from '@genaios/types';

import * as ReportServices from '../services/report.service';

const initialState: IReportState = {
  loading: false,
  report: undefined,
  creating: false,

  reports: {
    loading: false,
    data: undefined,
  },
};

export const getReports = createAsyncThunk(
  'report/getReports',
  async (
    { page, size, sortBy, sortDirection, search, typeOfDevice, typeOfScan }: IPaginationParams & ISortingParams & IFiltersParams,
    { rejectWithValue },
  ) => {
    try {
      return await ReportServices.getReports(page, size, sortBy, sortDirection, search, typeOfDevice, typeOfScan);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getReportById = createAsyncThunk('report/getReportById', async (reportId: string, { rejectWithValue }) => {
  try {
    return await ReportServices.getReportById(reportId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createReport = createAsyncThunk('report/createReport', async (scanHistoryId: string, { rejectWithValue }) => {
  try {
    return await ReportServices.createReport({ scanHistoryId });
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteReport = createAsyncThunk('report/deleteReport', async (reportId: number, { rejectWithValue }) => {
  try {
    return await ReportServices.deleteReport(reportId);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const renameReport = createAsyncThunk('report/renameReport', async (payload: { reportId: number; title: string }, { rejectWithValue }) => {
  try {
    return await ReportServices.renameReport(payload.reportId, payload.title);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReportById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReportById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.report = payload;
      })
      .addCase(getReportById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getReports.pending, (state) => {
        state.reports.loading = true;
      })
      .addCase(getReports.fulfilled, (state, { payload }) => {
        state.reports.loading = false;
        state.reports.data = payload;
      })
      .addCase(getReports.rejected, (state) => {
        state.reports.loading = false;
      })

      .addCase(deleteReport.pending, (state) => {
        state.reports.loading = true;
      })

      .addCase(deleteReport.fulfilled, (state, { meta }) => {
        state.reports.loading = false;
        if (state?.reports?.data) {
          state.reports.data = {
            ...state.reports.data,
            results: state?.reports?.data?.results?.filter((item) => item.id !== meta?.arg),
          };
        }
      })

      .addCase(deleteReport.rejected, (state) => {
        state.reports.loading = false;
      })

      .addCase(createReport.pending, (state) => {
        state.creating = true;
      })
      .addCase(createReport.fulfilled, (state) => {
        state.creating = false;
      })
      .addCase(createReport.rejected, (state) => {
        state.reports.loading = false;
      })
      .addCase(renameReport.pending, (state) => {
        state.reports.loading = true;
      })
      .addCase(renameReport.fulfilled, (state, { meta }) => {
        state.reports.loading = false;
        if (state.reports.data?.results)
          state.reports.data = {
            ...state.reports.data,
            results: state.reports.data?.results.map((item) => (item.id === meta.arg.reportId ? { ...item, name: meta.arg.title } : item)),
          };
      })
      .addCase(renameReport.rejected, (state) => {
        state.reports.loading = false;
      });
  },
});

export const reportReducer = reportSlice.reducer;
