export interface IReportState {
  loading: boolean;
  report?: ISingleReport;
  creating: boolean;

  reports: {
    data?: IReports;
    loading: boolean;
  };
}

export interface IReport {
  date: number;
  device: string;
  model: string;

  externId: string;
  id: number;
  name: string;
  scanHistoryId: string;
  type: string;
  user: string;
}

export interface IReports {
  results: IReport[];
  totalDocuments: number;
  totalPages: number;
}

export interface ISingleReport {
  report: IReport;
  result: {
    date: number;
    device: string;
    model: string;
    result: string;
    text: string;
    title: string;
    url?: string;
  };
}

export enum EReportType {
  IDENTIFICATION = 'identification',
  FACT_CHECKING = 'fact_checking',
}

export interface IDetectedClaimsConvertedResponse {
  id: string;
  predictions: [
    {
      claim: {
        value: number;
        label: string;
      };
    },
  ];
  logitsOutput: [number, number];
  text: string;
}
