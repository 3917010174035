import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { register } from 'store/features/auth-slice';
import { useAppDispatch } from 'store/hooks';

export const useGenaiosGoogleLogin = (googleUser: { access_token?: string; token_type?: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (googleUser?.access_token) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
          headers: {
            Authorization: `${googleUser?.token_type} ${googleUser.access_token}`,
            Accept: 'application/json',
          },
        })
        .then(({ data }) => {
          dispatch(
            register({
              username: data.email,
              firstname: data.name,
              country: 'Spain',
              isGoogle: 1,
            }),
          )
            .unwrap()
            .then(() => {
              navigate('/');
            })
            .catch(() => {});
        })
        .catch(() => {});
    }
  }, [googleUser]);
};
