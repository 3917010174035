import { createAxios } from '@genaios/config/axios';
import { IAnalyseDetectClaimsData, IProcessDocumentPayload } from '@genaios/types';

export const processDocument = async ({
  document,
  model,
  page,
  onUploadProgress,
}: IProcessDocumentPayload): Promise<{
  headers: { [key: string]: string[] };
  body: string | { data: IAnalyseDetectClaimsData[]; resultId: string };
}> =>
  createAxios().post(`/v2/processDocumentMultiLanguage/${model}/${page}`, document, {
    onUploadProgress,
  });
