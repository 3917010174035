import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';

import { DropdownProps } from '@genaios/types';

export const ScanningOptionsDropdown = ({ newScan, ...props }: { newScan?: boolean } & DropdownProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: newScan ? '20px' : 0, flexDirection: { xs: 'column', sm: 'row' } }}>
      <Typography variant='h6' color='#111928' align='left' fontWeight='600'>
        {props.title}
      </Typography>

      {newScan ? (
        <FormControl sx={{ width: { xs: '100%', sm: '250px' }, height: '48px' }}>
          <Select {...props} displayEmpty inputProps={{ 'aria-label': 'Without label' }} sx={{ Width: '250px', height: '48px' }}>
            {props.options.map((option) => (
              <MenuItem
                value={option}
                key={option}
                sx={{
                  '&.Mui-selected, &.Mui-selected:focus': {
                    backgroundColor: 'black',
                    color: 'white',
                  },
                  '&:hover, &.Mui-selected:hover': {
                    backgroundColor: '#F3F4F6',
                    color: 'black',
                  },
                }}>
                <>{option}</>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <></>
      )}
    </Box>
  );
};
