import { useState } from 'react';
import { NebulySdk } from '@nebuly-ai/javascript';

import { Constants } from '@genaios/constants/common';
import { ENebulySlug, IInteractionSessionWithNebulyPayload } from '@genaios/types';

import { startInteractionSessionWithNebuly } from 'store/features/analyze-text-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useNebulyInteractions = (analyseOption: string, isDocumentScan = false) => {
  const dispatch = useAppDispatch();

  const { nebulyAnalyseReport: nebulyAnalyseReportText } = useAppSelector((state) => state.analyzeText);
  const { nebulyAnalyseReport: nebulyAnalyseReportDocument } = useAppSelector((state) => state.analyzeDocument);

  const nebulyAnalyseReport = isDocumentScan ? nebulyAnalyseReportDocument : nebulyAnalyseReportText;

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const { user } = useAppSelector((state) => state.auth);

  const nebuly = new NebulySdk(Constants.Nebuly.Token!, {
    end_user: user?.username as string,
  });

  const onSendAction = (selectedReaction: ENebulySlug, comment = '') => {
    nebuly
      .sendAction(
        { slug: selectedReaction as ENebulySlug, text: comment },
        {
          input: nebulyAnalyseReport?.payload,
          output: nebulyAnalyseReport?.response,
        },
      )
      .then(() => {
        setFeedbackSubmitted(true);
      });
  };

  const onStartInteractionSessionWithNebuly = (reaction?: ENebulySlug) => {
    const now = new Date().toISOString();
    const payload: IInteractionSessionWithNebulyPayload = {
      interaction: {
        input: nebulyAnalyseReport?.payload || ' ',
        output: nebulyAnalyseReport?.response || ' ',
        time_start: now,
        time_end: now,
        history: [],
        end_user: user?.username as string,
        model: analyseOption,
        system_prompt: reaction || 'initial_request',
        tags: { origin: 'Web' },
      },
      anonymize: true,
    };

    dispatch(startInteractionSessionWithNebuly(payload));
  };

  return { onStartInteractionSessionWithNebuly, feedbackSubmitted, onSendAction };
};
