import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { IReport } from '@genaios/types';

const style = {
  position: 'absolute',
  top: '35%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  height: '100px',
  bgcolor: '#FFFFFF',
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  p: 2,
};

export const ShareReport = ({ onCancel, report }: { onCancel: () => void; report: IReport }) => {
  const { t } = useTranslation();

  const onCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/public/reports/${report?.model?.toLowerCase()}/${report?.externId}`);
    toast.success(t('reports.copyLinkSuccess'));

    onCancel();
  };

  return (
    <Modal open={true} onClose={onCancel} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={style}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid>
            <Typography variant='subtitle1' fontWeight='bold'>
              {t('reports.shareReport')}
            </Typography>
            <Typography variant='subtitle2'>{t('reports.viewReport')}</Typography>
          </Grid>

          <Grid>
            <Button onClick={onCopyLink} variant='contained'>
              {t('buttons.copyLink')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
