import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from 'translation/i18n';

import { Constants } from '@genaios/constants/common';
import { languages } from '@genaios/constants/language';
import { ELoadingState, EUserLanguage, IRegisterPayload, IResetPasswordPayload } from '@genaios/types';
import { EAuthorized, IAuthState, ILoginPayload, IUpdateUserPayload } from '@genaios/types';

import * as AuthServices from 'store/services/auth.service';

const initialState: IAuthState = {
  token: null,
  loginState: ELoadingState.UNINITIALIZED,
  registerState: ELoadingState.UNINITIALIZED,
  forgotPasswordState: ELoadingState.UNINITIALIZED,
  resetPasswordState: ELoadingState.UNINITIALIZED,
  updateUserState: ELoadingState.UNINITIALIZED,
  authActionState: ELoadingState.UNINITIALIZED,
  isAuthorized: EAuthorized.UNINITIALIZED,
  user: undefined,
  deleting: ELoadingState.UNINITIALIZED,
};

export const login = createAsyncThunk('auth/login', async (params: ILoginPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.login(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const register = createAsyncThunk('auth/register', async (params: IRegisterPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.register(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (params: { username: string }, { rejectWithValue }) => {
  try {
    return await AuthServices.forgotPassword(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (params: IResetPasswordPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.resetPassword(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updatePassword = createAsyncThunk('auth/updatePassword', async (params: IResetPasswordPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.updatePassword(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateUser = createAsyncThunk('auth/updateUser', async (params: IUpdateUserPayload, { rejectWithValue }) => {
  try {
    return await AuthServices.updateUser(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const isTokenValid = createAsyncThunk('auth/isTokenValid', async (_, { rejectWithValue }) => {
  try {
    return await AuthServices.isTokenValid();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserByToken = createAsyncThunk('auth/getUserByToken', async (token: string, { rejectWithValue }) => {
  try {
    return await AuthServices.getUserByToken(token);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const isValidCounterUser = createAsyncThunk('auth/isValidCounterUser', async (token: string, { rejectWithValue }) => {
  try {
    return await AuthServices.isValidCounterUser(token);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteUser = createAsyncThunk('auth/deleteUser', async (_, { rejectWithValue }) => {
  try {
    return await AuthServices.deleteUser();
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateUserLanguage = createAsyncThunk(
  'auth/updateUserLanguage',
  async (body: { language: EUserLanguage; token: string }, { rejectWithValue }) => {
    try {
      return await AuthServices.updateUserLanguage(body);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    setUnAuthorize: (state) => {
      state.token = null;
      state.user = undefined;
      state.isAuthorized = EAuthorized.UNAUTHORIZED;
      localStorage.removeItem(Constants.LocalStorage.ACCESS_TOKEN);
    },
    logout: (state) => {
      authSlice.caseReducers.setUnAuthorize(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginState = ELoadingState.LOADING;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loginState = ELoadingState.SUCCESS;
        if (payload?.token) {
          state.token = payload.token;
          state.isAuthorized = EAuthorized.AUTHORIZED;
          state.user = payload.user;
          localStorage.setItem(Constants.LocalStorage.ACCESS_TOKEN, payload.token);
        }
      })
      .addCase(login.rejected, (state) => {
        state.loginState = ELoadingState.FAILED;
      })

      .addCase(register.pending, (state) => {
        state.registerState = ELoadingState.LOADING;
      })
      .addCase(register.fulfilled, (state, { payload }) => {
        state.registerState = ELoadingState.SUCCESS;
        if (payload?.token) {
          state.token = payload.token;
          state.isAuthorized = EAuthorized.AUTHORIZED;
          state.user = payload.user;
          localStorage.setItem(Constants.LocalStorage.ACCESS_TOKEN, payload.token);
        }
      })
      .addCase(register.rejected, (state) => {
        state.registerState = ELoadingState.FAILED;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordState = ELoadingState.LOADING;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.forgotPasswordState = ELoadingState.SUCCESS;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.forgotPasswordState = ELoadingState.FAILED;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordState = ELoadingState.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPasswordState = ELoadingState.SUCCESS;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPasswordState = ELoadingState.FAILED;
      })
      .addCase(updatePassword.pending, (state) => {
        state.resetPasswordState = ELoadingState.LOADING;
      })
      .addCase(updatePassword.fulfilled, (state) => {
        state.resetPasswordState = ELoadingState.SUCCESS;
      })
      .addCase(updatePassword.rejected, (state) => {
        state.resetPasswordState = ELoadingState.FAILED;
      })
      .addCase(updateUser.pending, (state) => {
        state.updateUserState = ELoadingState.LOADING;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.updateUserState = ELoadingState.SUCCESS;
      })
      .addCase(updateUser.rejected, (state) => {
        state.updateUserState = ELoadingState.FAILED;
      })
      .addCase(isTokenValid.fulfilled, (state, { payload }) => {
        if (!payload) {
          authSlice.caseReducers.setUnAuthorize(state);
        }
      })
      .addCase(isTokenValid.rejected, (state) => {
        authSlice.caseReducers.setUnAuthorize(state);
      })
      .addCase(getUserByToken.fulfilled, (state, { payload, meta }) => {
        if (payload) {
          state.token = meta.arg;
          state.user = payload;
          state.isAuthorized = EAuthorized.AUTHORIZED;
          i18n.changeLanguage(languages[payload.language]);
        }
      })
      .addCase(getUserByToken.rejected, (state) => {
        authSlice.caseReducers.setUnAuthorize(state);
      })
      .addCase(deleteUser.pending, (state) => {
        state.deleting = ELoadingState.LOADING;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.deleting = ELoadingState.SUCCESS;
        authSlice.caseReducers.setUnAuthorize(state);
      })
      .addCase(deleteUser.rejected, (state) => {
        state.deleting = ELoadingState.FAILED;
      })
      .addCase(updateUserLanguage.fulfilled, (state, { meta }) => {
        if (state.user) state.user = { ...state.user, language: meta.arg.language };
        i18n.changeLanguage(languages[meta.arg.language]);
      });
  },
});

export const authReducer = authSlice.reducer;
export const { resetAuthState, logout, setUnAuthorize } = authSlice.actions;
