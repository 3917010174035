import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import 'react-circular-progressbar/dist/styles.css';

import { AnalyzeText } from '@genaios/components/AnalyzeText/AnalyzeText';
import { ErrorModal } from '@genaios/components/ErrorModal/ErrorModal';
import { ScanningOptionsDropdown } from '@genaios/components/ScanningOptionsDropdown/ScanningOptionsDropdown';
import { Constants } from '@genaios/constants/common';
import { useNebulyInteractions } from '@genaios/hooks/nebuly';
import { IAnalysePayload, IPrediction, ModelObject } from '@genaios/types/';

import { resetToInitialState } from 'store/features/analyze-text-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { AIContentResults } from '../AIContentResults/AIContentResults';
import { FactCheckingResults } from '../FactCheckingResults/FactCheckingResults';
import { Nebuly } from '../Nebuly/Nebuly';
interface IResultData {
  predictions: IPrediction[];
  text: string;
  attributes: ModelObject[];
}

export const TextScan = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [type, setType] = useState<string>(Constants.AnalyseOptions[0]);
  const [scannedText, setScannedText] = useState<IAnalysePayload[]>([]);
  const [data, setData] = useState<IResultData[] | null>([]);
  const [newScan, setNewScan] = useState(true);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const { textIdentificationData, claimsData, loading } = useAppSelector((state) => state.analyzeText);

  const { onStartInteractionSessionWithNebuly } = useNebulyInteractions(type);

  const handleChangeTypeCheck = (event: SelectChangeEvent<unknown>) => {
    setType(event.target.value as string);
  };

  const handleScanNewText = () => {
    setNewScan(true);
    setData([]);
    dispatch(resetToInitialState());
  };

  useEffect(() => {
    setType(Constants.AnalyseOptions[0]);
  }, []);

  useEffect(() => {
    if (textIdentificationData && textIdentificationData.length !== 0) {
      const resultData: IResultData[] = [];
      for (let i = 0; i < textIdentificationData.length; i++) {
        const item = textIdentificationData[i];

        resultData.push({ ...item, text: scannedText[i]?.text });
      }

      setData(resultData);
    } else {
      setData([]);
    }
  }, [textIdentificationData, scannedText]);

  useEffect(() => {
    if (textIdentificationData && !Array.isArray(textIdentificationData)) {
      setOpenErrorModal(true);
    } else if (textIdentificationData || claimsData) {
      setNewScan(false);
      onStartInteractionSessionWithNebuly();
    }
  }, [textIdentificationData, claimsData]);

  return (
    <Box>
      <ScanningOptionsDropdown
        title={newScan ? t('scanFeatures.textScan') : ''}
        value={type}
        newScan={newScan}
        options={Constants.AnalyseOptions}
        onChange={handleChangeTypeCheck}
      />

      {loading && !claimsData && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '70px' }}>
          <img src={require('../../../assets/load.gif')} />
        </Box>
      )}

      {(!textIdentificationData && !claimsData) || newScan ? (
        <AnalyzeText type={type} setNewScan={setNewScan} setOpenErrorModal={setOpenErrorModal} setScannedText={setScannedText} />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            sx={{
              border: '1px solid #DFE4EA',
              width: '100%',
              padding: '20px 5px 50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '30px',
              borderRadius: '8px',
            }}>
            <Typography variant='h6' color='#111928' align='left' fontWeight='600' alignSelf='start' display={{ xs: 'none' }}>
              {t('scanFeatures.textScan')}
            </Typography>

            <Typography sx={{ fontSize: '22px', fontWeight: '600', alignSelf: 'flex-start', mb: '20px', display: { sm: 'none' } }}>
              {t('scanFeatures.scanningResults')}
            </Typography>

            {data && data.length > 0 && <AIContentResults resultsData={data} />}

            {claimsData && <FactCheckingResults claimsData={claimsData} />}
          </Box>

          <Button
            onClick={handleScanNewText}
            component='label'
            variant='contained'
            tabIndex={-1}
            sx={{ alignSelf: { xs: 'start', sm: 'end' }, marginTop: '20px' }}>
            {t('scanFeatures.scanNewText')}
          </Button>
        </Box>
      )}

      <Nebuly analyseOption={type} />
      <ErrorModal open={openErrorModal} handleClose={() => setOpenErrorModal(false)} handleOpen={() => setOpenErrorModal(true)} />
    </Box>
  );
};
