import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';

export const ReportHeader = () => {
  const { t } = useTranslation();
  return (
    <Grid
      flexDirection='row'
      px={2}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      style={{ backgroundColor: '#c5ff96', position: 'sticky', top: 0 }}>
      <Typography component='span' fontWeight='bold' variant='subtitle1'>
        {t('reports.scanningReport')}
      </Typography>

      <Box
        component='img'
        src={require('assets/images/genaios-logo-black.svg').default}
        width='120'
        height='70'
        alt='Genaios'
        sx={{ display: { xs: 'none', sm: 'block' } }}
      />
    </Grid>
  );
};
