import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  OutlinedInput,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './ScanHistory.css';

import { BulkActionsDropdownMenu } from '@genaios/components/BulkActionsDropdownMenu/BulkActionsDropdownMenu';
import { DeleteModal } from '@genaios/components/DeleteModal/DeleteModal';
import { SortingTableCell } from '@genaios/components/SortingTableCell/SortingTableCell';
import { TableFilters } from '@genaios/components/TableFilters/TableFilters';
import { TableLoading } from '@genaios/components/TableLoading/TableLoading';

import { createReport } from 'store/features/report-slice';
import { deleteScanHistory, getScanHistory, renameUserResult } from 'store/features/scan-history-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { toHumanReadable } from '../../@genaios/utils/helper';

import { EmptyScanHistory } from './@components/EmptyScanHistory';

const initialSortState = { sortKey: '', sortDirection: '' };
const initialFilterState = { typeOfScan: '', typeOfDevice: '', search: '' };

const ScanHistory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { scanHistoryData, loading } = useAppSelector((state) => state.scanHistory);
  const { creating } = useAppSelector((state) => state.report);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of rows per page
  const [sorting, setSorting] = useState(initialSortState);
  const [filters, setFilters] = useState(initialFilterState);
  const [selectedItemId, setSelectedItemId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bulkSelectedDocuments, setBulkSelectedDocuments] = useState<string[]>([]);
  const [hoveredCell, setHoveredCell] = useState<string | undefined>(undefined);
  const [showNameInput, setShowNameInput] = useState(false);
  const [changedName, setChangedName] = useState<string | undefined>(undefined);

  const handleMouseEnter = (id: string) => {
    if (!showNameInput) setHoveredCell(id);
  };

  const handleMouseLeave = () => {
    if (!showNameInput) setHoveredCell(undefined);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onNameChange = (resultId?: string) => {
    if (changedName === undefined) {
      return toast.warning(t('scanHistory.sameDocumentName'));
    }
    if (changedName === '') {
      return toast.warning(t('scanHistory.emptyDocumentName'));
    }

    if (!resultId) return;

    dispatch(
      renameUserResult({
        resultId,
        title: changedName,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(t('scanHistory.renameSuccess'));
        setHoveredCell(undefined);
        setShowNameInput(false);
        setChangedName(undefined);
      })
      .catch(() => {
        toast.error(t('scanHistory.renameError'));
      });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onOpenDeleteModal = (id: string) => {
    setSelectedItemId(id);
    setBulkSelectedDocuments([]);
    setShowDeleteModal(true);
  };

  const onBulkDeleteDocuments = () => {
    setShowDeleteModal(false);
    bulkSelectedDocuments.map((document, index) =>
      dispatch(deleteScanHistory(document))
        .unwrap()
        .then(() => {
          if (bulkSelectedDocuments.length === index + 1) {
            dispatch(getScanHistory({ page: page + 1, size: rowsPerPage }));
          }
        }),
    );
  };

  const onDeleteScanHistoryItem = () => {
    if (selectedItemId) {
      dispatch(deleteScanHistory(selectedItemId))
        .unwrap()
        .then(() => {
          setShowDeleteModal(false);
          dispatch(getScanHistory({ page: page + 1, size: rowsPerPage }));
        })
        .catch(() => {});
    }
  };

  const onCreateReport = (scanHistoryId: string) => {
    setSelectedItemId(scanHistoryId);
    dispatch(createReport(scanHistoryId))
      ?.unwrap()
      .then((res) => {
        navigate(`/reports/${res?.model?.toLowerCase()}/${res.externId}`);
      })
      .catch(() => {});
  };

  const onDocumentCheck = (checked: boolean, id: string) => {
    if (checked) {
      setBulkSelectedDocuments((prev) => [...prev, id]);
      return;
    }
    setBulkSelectedDocuments((prev) => prev.filter((item) => item !== id));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = scanHistoryData?.results?.map((n) => n.id) as string[];
      setBulkSelectedDocuments(newSelected);
      return;
    }
    setBulkSelectedDocuments([]);
  };

  useEffect(() => {
    dispatch(
      getScanHistory({
        page: page + 1,
        size: rowsPerPage,
        sortDirection: sorting.sortDirection || undefined,
        sortBy: sorting.sortKey || undefined,
        search: filters.search || undefined,
        typeOfDevice: filters.typeOfDevice || undefined,
        typeOfScan: filters.typeOfScan || undefined,
      }),
    );
  }, [dispatch, page, rowsPerPage, sorting, filters]);

  if (!scanHistoryData?.results?.length && loading) {
    return (
      <Box justifyContent='center' display='flex' alignItems='center' sx={{ height: '65vh' }}>
        <CircularProgress />
      </Box>
    );
  } else if (!scanHistoryData?.results?.length && !filters?.typeOfScan && !filters?.typeOfDevice && !filters?.search) {
    return <EmptyScanHistory />;
  }

  return (
    <Box>
      <Typography sx={{ flex: '1 1 100%', fontSize: '22px', fontWeight: '600', pb: '10px' }} variant='h6' id='tableTitle' component='div'>
        {t('scanHistory.scanHistory')}
      </Typography>

      <TableFilters
        onSaveFilters={(value) => setFilters(() => ({ search: value.documentName, typeOfDevice: value.typeOfDevice, typeOfScan: value.typeOfScan }))}
      />

      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead sx={{ backgroundColor: '#F3F4F6', fontSize: '15px', fontWeight: '500', color: '#111928' }}>
            <TableRow>
              <TableCell padding='checkbox' style={{ width: '10%' }}>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <Checkbox
                      color='primary'
                      indeterminate={false}
                      checked={scanHistoryData?.results?.length === bulkSelectedDocuments.length}
                      onChange={handleSelectAllClick}
                    />
                  </Grid>
                  {bulkSelectedDocuments?.length > 0 ? (
                    <Grid item>
                      <BulkActionsDropdownMenu onBulkDelete={() => setShowDeleteModal(true)} />
                    </Grid>
                  ) : null}
                </Grid>
              </TableCell>
              <SortingTableCell
                title={t('scanHistory.title')}
                sortKey='title'
                activeSortKey={sorting.sortKey}
                activeSortDirection={sorting.sortDirection}
                onSort={(value) => setSorting(value)}
              />
              <SortingTableCell
                title={t('scanHistory.date')}
                sortKey='date'
                centerTitle
                activeSortKey={sorting.sortKey}
                activeSortDirection={sorting.sortDirection}
                onSort={(value) => setSorting(value)}
              />
              <SortingTableCell
                title={t('scanHistory.scan')}
                sortKey='scan'
                centerTitle
                activeSortKey={sorting.sortKey}
                activeSortDirection={sorting.sortDirection}
                onSort={(value) => setSorting(value)}
              />
              <SortingTableCell
                title={t('scanHistory.device')}
                sortKey='device'
                centerTitle
                activeSortKey={sorting.sortKey}
                activeSortDirection={sorting.sortDirection}
                onSort={(value) => setSorting(value)}
              />
              <TableCell align='right' sx={{ fontSize: '15px', fontWeight: '500', color: '#111928' }} />
            </TableRow>
          </TableHead>

          {loading ? <TableLoading /> : <></>}

          <TableBody>
            {scanHistoryData?.results?.map((row) => (
              <TableRow key={row?.id}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='primary'
                    checked={bulkSelectedDocuments.includes(row.id)}
                    onChange={(_, checked) => onDocumentCheck(checked, row.id)}
                  />
                </TableCell>
                <TableCell sx={{ width: '40%' }} onMouseEnter={() => handleMouseEnter(row.id)} onMouseLeave={handleMouseLeave}>
                  {showNameInput && row.id === hoveredCell ? (
                    <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                      <Grid item>
                        <OutlinedInput defaultValue={row?.title} size='small' onChange={(e) => setChangedName(e.target.value)} />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => setShowNameInput(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => onNameChange(row?.id)}>
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container direction='row' spacing={2} alignItems={'center'}>
                      <Grid item style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        <img src={require('assets/images/scan-icon.svg').default} alt='scan' style={{ paddingRight: '10px' }}></img>

                        <Tooltip title={row?.title && row?.title?.length > 30 && row?.title}>
                          <span>{row?.title && row?.title?.length < 30 ? row?.title : row?.title?.slice(0, 30) + '...'}</span>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        {row?.id === hoveredCell ? (
                          <Button
                            sx={{ fontSize: '0.8rem', textTransform: 'none', color: '#9f9f9f' }}
                            variant='text'
                            startIcon={<EditCalendarIcon />}
                            onClick={() => setShowNameInput(true)}>
                            {t('scanHistory.editName')}
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  )}
                </TableCell>

                <TableCell align='center' sx={{ width: '15%' }}>
                  {dayjs(row?.date).format('DD/MM/YYYY')}
                </TableCell>

                <TableCell align='center' sx={{ width: '15%' }}>
                  <Box
                    sx={{
                      padding: '3px 10px',
                      backgroundColor: '#6373811A',
                      borderRadius: '30px',
                      color: '#637381',
                      fontWeight: '500',
                      fontSize: '12px',
                    }}>
                    {row?.model === 'IDENTIFICATION'
                      ? t('scanHistory.aiScan')
                      : row?.model === 'ATTRIBUTES'
                        ? t('scanHistory.attributes')
                        : row?.model === 'CLAIM_DETECTION'
                          ? t('reports.claimDetection')
                          : t('scanHistory.factChecking')}
                  </Box>
                </TableCell>

                <TableCell align='center' sx={{ width: '15%' }}>
                  {toHumanReadable(row?.device)}
                </TableCell>

                <TableCell scope='row' align='right'>
                  <Grid display='flex' justifyContent='end'>
                    <Button onClick={() => onCreateReport(row?.id)} sx={{ borderRadius: '15px', marginRight: 1 }} size='small' variant='outlined'>
                      {selectedItemId === row.id && creating ? t('scanHistory.creating') : t('scanHistory.report')}
                    </Button>

                    <img
                      onClick={() => onOpenDeleteModal(row.id)}
                      src={require('assets/images/trash-can.svg').default}
                      alt='scan'
                      style={{ cursor: 'pointer' }}
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {scanHistoryData && (
        <Box display='flex' justifyContent='flex-end'>
          <TablePagination
            className='pagination'
            rowsPerPageOptions={[5, 10, 25, 50]}
            component='div'
            count={scanHistoryData?.totalDocuments}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}

      <DeleteModal
        open={showDeleteModal}
        isBulkDelete={bulkSelectedDocuments.length > 0}
        onCancel={() => setShowDeleteModal(false)}
        onSave={bulkSelectedDocuments.length > 0 ? onBulkDeleteDocuments : onDeleteScanHistoryItem}
      />
    </Box>
  );
};

export default ScanHistory;
