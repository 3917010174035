import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Constants } from '../constants/common';

export const Footer = ({ drawerWidth }: { drawerWidth: number }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        bottom: 0,
        paddingY: '15px',
        backgroundColor: '#fff',
        width: { xs: '90%', md: `calc(100% - ${drawerWidth + 40}px)` },
      }}>
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 600,
          color: '#9F9F9F',
        }}>
        Genaios 2024
      </Typography>
      <Typography
        sx={{
          fontSize: 12,
          fontWeight: 600,
          color: '#9F9F9F',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault();
          window.open(Constants.PrivacyPolicy.url, '_blank');
        }}>
        Privacy Policy
      </Typography>
    </Box>
  );
};
