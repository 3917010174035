import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IInteractionSessionWithNebulyPayload } from '@genaios/types';
import { IAnalysePayload, IAnalyseState } from '@genaios/types/';

import * as AnalyseServices from '../services/analyze-text.service';
import * as CommonServices from '../services/common.service';

const initialState: IAnalyseState = {
  loading: false,
  textIdentificationData: undefined,
  factCheckingData: undefined,
  claimsData: undefined,
  nebulyAnalyseReport: {
    payload: undefined,
    response: undefined,
  },
};

export const analyseTextIdentification = createAsyncThunk(
  'analyse/analyseTextIdentification',
  async (params: IAnalysePayload[], { rejectWithValue }) => {
    try {
      return await AnalyseServices.analyseTextIdentification(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const detectClaims = createAsyncThunk('analyse/detectClaims', async (params: IAnalysePayload[], { rejectWithValue }) => {
  try {
    return await AnalyseServices.claimDetect(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const analyseFactChecking = createAsyncThunk('analyse/analyseFastChecking', async (params: IAnalysePayload[], { rejectWithValue }) => {
  try {
    return await AnalyseServices.analyseFactChecking(params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const startInteractionSessionWithNebuly = createAsyncThunk(
  'analyse/startInteractionSessionWithNebuly',
  async (params: IInteractionSessionWithNebulyPayload, { rejectWithValue }) => {
    try {
      return await CommonServices.startInteractionSessionWithNebuly(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const analyseSlice = createSlice({
  name: 'analyse',
  initialState,
  reducers: {
    resetToInitialState: (state) => {
      state.textIdentificationData = undefined;
      state.factCheckingData = undefined;
      state.claimsData = undefined;

      state.nebulyAnalyseReport = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(analyseTextIdentification.pending, (state) => {
        state.loading = true;
        analyseSlice.caseReducers.resetToInitialState(state);
      })
      .addCase(analyseTextIdentification.fulfilled, (state, { payload, meta }) => {
        state.loading = false;
        state.textIdentificationData = payload?.data;

        state.nebulyAnalyseReport = {
          payload: JSON.stringify(meta.arg),
          response: JSON.stringify(payload),
        };
      })
      .addCase(analyseTextIdentification.rejected, (state) => {
        state.loading = false;
      })

      .addCase(analyseFactChecking.pending, (state) => {
        state.loading = true;
      })
      .addCase(analyseFactChecking.fulfilled, (state, { payload, meta }) => {
        state.loading = false;
        state.factCheckingData = payload?.data;

        state.nebulyAnalyseReport = {
          payload: JSON.stringify(meta.arg),
          response: JSON.stringify(payload),
        };
      })
      .addCase(analyseFactChecking.rejected, (state) => {
        state.loading = false;
      })

      .addCase(detectClaims.pending, (state) => {
        state.loading = true;
        analyseSlice.caseReducers.resetToInitialState(state);
      })
      .addCase(detectClaims.fulfilled, (state, { payload, meta }) => {
        state.loading = false;
        state.claimsData = payload?.data;

        state.nebulyAnalyseReport = {
          payload: JSON.stringify(meta.arg),
          response: JSON.stringify(payload),
        };
      })
      .addCase(detectClaims.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const analyzeTextReducer = analyseSlice.reducer;
export const { resetToInitialState } = analyseSlice.actions;
