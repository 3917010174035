import * as React from 'react';

interface IProps {
  color: string;
}

const AddFilesSvgIcon = (props: IProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill={props.color} {...props}>
    <path
      fill={props.color || '#9F9F9F'}
      d='M14.475 8.287h-1.612V6.675A.872.872 0 0 0 12 5.813a.872.872 0 0 0-.863.862v1.612H9.525a.872.872 0 0 0-.862.863c0 .487.375.862.862.862h1.612v1.613c0 .45.375.863.863.863.45 0 .863-.375.863-.863v-1.613h1.612c.45 0 .863-.375.863-.862a.872.872 0 0 0-.863-.863Z'
    />
    <path
      fill={props.color || '#9F9F9F'}
      d='M18.75.675H5.25a2.273 2.273 0 0 0-2.287 2.288V15c0 .563.225 1.087.637 1.462l6.75 6.375c.375.375.863.563 1.388.563h7.012a2.273 2.273 0 0 0 2.288-2.287V2.963A2.273 2.273 0 0 0 18.75.675Zm-12.862 15.6h4.387c.188 0 .338.15.338.3V20.7l-4.725-4.425Zm13.462 4.763c0 .337-.263.6-.6.6H12.3v-5.063c0-1.087-.9-1.987-2.025-1.987H4.65V2.963c0-.338.263-.6.6-.6h13.5c.337 0 .6.262.6.6v18.075Z'
    />
  </svg>
);
export default AddFilesSvgIcon;
