import { SelectProps } from '@mui/material';
export interface ICommon<T> {
  common: T;
}

export enum ELoadingState {
  UNINITIALIZED = 0,
  LOADING,
  SUCCESS,
  FAILED,
}

export interface DropdownProps extends SelectProps {
  options: string[];
  newScan: boolean;
}

export interface ErrorModalProps {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

export interface DeleteModalProps {
  open: boolean;
  isBulkDelete?: boolean;
  onCancel: () => void;
  onSave: () => void;
}

export interface IInteractionSessionWithNebulyPayload {
  interaction: {
    input: string;
    output: string;
    time_start: string;
    time_end: string;
    history: [];
    end_user: string;
    model: string;
    system_prompt: string;
    tags: {
      origin: string;
    };
  };
  anonymize: boolean;
}

export enum ENebulySlug {
  THUMBS_UP = 'thumbs_up',
  THUMBS_DOWN = 'thumbs_down',
}

export interface IPaginationParams {
  page?: number;
  size?: number;
  search?: string;
}

export interface ISortingParams {
  sortBy?: string;
  sortDirection?: string;
}

export interface IFiltersParams {
  typeOfDevice?: string;
  typeOfScan?: string;
}

export enum EScanType {
  IDENTIFICATION = 'IDENTIFICATION',
  CLAIM_DETECTION = 'CLAIM_DETECTION',
  FACT_CHECKING = 'FACT_CHECKING',
}

export enum EDeviceType {
  WEB = 'Web',
  PLUGIN = 'Plugin',
}
