import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';

import { CloseOutlined, SearchOutlined } from '@mui/icons-material';
import { Button, Chip, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { EDeviceType, EScanType } from '@genaios/types';

interface IFilters {
  documentName: string;
  typeOfScan: string;
  typeOfDevice: string;
}

interface IProps {
  onSaveFilters: (value: IFilters) => void;
}

const initialFiltersState = { documentName: '', typeOfScan: '', typeOfDevice: '' };

export const TableFilters = ({ onSaveFilters }: IProps) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<IFilters>(initialFiltersState);
  const [savedFilters, setSavedFilters] = useState<IFilters>(initialFiltersState);

  const scanTypeLabels: Record<EScanType, string> = {
    CLAIM_DETECTION: t('reports.claimDetection'),
    FACT_CHECKING: t('scanHistory.factChecking'),
    IDENTIFICATION: t('reports.aiScan'),
  };

  const deviceTypeLabels: Record<EDeviceType, string> = {
    Plugin: t('common.plugin'),
    Web: t('common.web'),
  };

  return (
    <div style={{ marginBottom: '30px' }}>
      <Grid container justifyContent={'space-between'}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item sx={{ marginTop: '10px' }}>
            <TextField
              sx={{ width: '300px' }}
              size='small'
              label={
                <>
                  <SearchOutlined />
                  {t('filters.documentName')}
                </>
              }
              value={filters?.documentName}
              variant='outlined'
              onChange={(e) => setFilters((prev) => ({ ...prev, documentName: e.target.value }))}
            />
          </Grid>
          <Grid item sx={{ width: '160px', marginLeft: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label' sx={{ width: '160px' }}>
                {t('filters.typeOfScan')}
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label={t('filters.typeOfScan')}
                sx={{ borderRadius: '20px', height: '36px', marginTop: '10px' }}
                value={filters?.typeOfScan}
                onChange={(event) => setFilters((prev) => ({ ...prev, typeOfScan: String(event.target.value) }))}>
                {Object.values(EScanType).map((item) => (
                  <MenuItem key={item} value={item}>
                    {scanTypeLabels[item]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sx={{ width: '160px', marginLeft: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label' sx={{ width: '160px' }}>
                {t('filters.typeOfDevice')}
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label={t('filters.typeOfDevice')}
                value={filters?.typeOfDevice}
                sx={{ borderRadius: '20px', height: '36px', marginTop: '10px' }}
                onChange={(event) => setFilters((prev) => ({ ...prev, typeOfDevice: String(event.target.value || '') }))}>
                {Object.values(EDeviceType).map((item) => (
                  <MenuItem key={item} value={item}>
                    {deviceTypeLabels[item]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </div>
        {!isEqual(filters, savedFilters) ? (
          <Grid item style={{ alignSelf: 'flex-end' }}>
            <Button
              variant='contained'
              size='small'
              sx={{ borderRadius: '20px' }}
              onClick={() => {
                setSavedFilters(filters);
                onSaveFilters(filters);
              }}>
              {t('filters.saveFilters')}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Divider sx={{ background: 'black', marginTop: '10px' }} />
      <Grid container justifyContent={'space-between'} mt={1}>
        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '8px' }}>
          {filters.documentName ? (
            <Grid item sx={{ marginTop: '10px' }}>
              <Chip
                label={filters.documentName}
                variant='outlined'
                style={{ borderColor: 'black' }}
                deleteIcon={<CloseOutlined style={{ color: 'black' }} />}
                onDelete={() => setFilters((prev) => ({ ...prev, documentName: '' }))}
              />
            </Grid>
          ) : null}
          {filters.typeOfScan ? (
            <Grid item sx={{ marginTop: '10px' }}>
              <Chip
                label={scanTypeLabels[filters.typeOfScan as EScanType]}
                variant='outlined'
                style={{ borderColor: 'black' }}
                deleteIcon={<CloseOutlined style={{ color: 'black' }} />}
                onDelete={() => setFilters((prev) => ({ ...prev, typeOfScan: '' }))}
              />
            </Grid>
          ) : null}
          {filters.typeOfDevice ? (
            <Grid item sx={{ marginTop: '10px' }}>
              <Chip
                label={deviceTypeLabels[filters.typeOfDevice as EDeviceType]}
                variant='outlined'
                style={{ borderColor: 'black' }}
                deleteIcon={<CloseOutlined style={{ color: 'black' }} />}
                onDelete={() => setFilters((prev) => ({ ...prev, typeOfDevice: '' }))}
              />
            </Grid>
          ) : null}
        </div>
        <Grid item style={{ alignSelf: 'flex-end' }}>
          <Button
            variant='contained'
            size='small'
            sx={{ borderRadius: '20px', background: '#CFCFCF', color: 'black' }}
            onClick={() => {
              setFilters(initialFiltersState);
              setSavedFilters(initialFiltersState);
              onSaveFilters(initialFiltersState);
            }}>
            {t('filters.clearFilters')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
