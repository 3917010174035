import { useEffect } from 'react';

import { Constants } from '@genaios/constants/common';

import { getUserByToken, isTokenValid, setUnAuthorize } from 'store/features/auth-slice';
import { useAppDispatch } from 'store/hooks';

export const useAppLoad = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const accessToken = localStorage.getItem(Constants.LocalStorage.ACCESS_TOKEN) || '';

    if (accessToken) {
      dispatch(isTokenValid())
        .unwrap()
        .then((res) => {
          if (res) {
            if (accessToken) {
              dispatch(getUserByToken(accessToken));
            }
          }
        })
        .catch(() => {});
    } else {
      dispatch(setUnAuthorize());
    }
  }, []);
};
