import { ELoadingState } from './common';

export enum EAuthorized {
  UNINITIALIZED = 0,
  AUTHORIZED,
  UNAUTHORIZED,
}

export enum EUserLanguage {
  English = 'English',
  Spanish = 'Spanish',
  German = 'German',
}

export interface ILoginPayload {
  username: string;
  password: string;
}

export interface IRegisterPayload {
  username: string;
  firstname: string;
  lastname?: string;
  password?: string;
  conf_password?: string;
  country: string;
  rememberMe?: boolean;
  isGoogle?: number;
  subscription?: number;
}

export interface IResetPasswordPayload {
  username: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export interface IUpdateUserPayload {
  name: string;
  username: string;
  token: string;
}

export interface ILoginResponse {
  email_not_verified?: boolean;
  token: string;
  user: IUser;
}

export interface IAuthState {
  authActionState: ELoadingState;
  loginState: ELoadingState;
  registerState: ELoadingState;
  updateUserState: ELoadingState;
  forgotPasswordState: ELoadingState;
  resetPasswordState: ELoadingState;
  isAuthorized: EAuthorized;
  deleting: ELoadingState;
  token: string | null;
  user?: IUser;
}

export interface IUser {
  id: number;
  username: string;
  lastname: string;
  firstname: string;
  country: string;
  language: EUserLanguage;
  password: string;
  tokenvalid: string;
  tokenreset: string;
  validated: number;
  activated: number;
  isGoogle: number;
  date_exp_reset: string | Date;
  date_exp_valid: string | Date;
  role: string | 'USER';
  accountNonExpired: boolean;
  accountNonLocked: true;
  credentialsNonExpired: true;
  authorities: { authority: string }[];
  enabled: boolean;
}

export interface IUserLanguage {
  id: number;
  language: EUserLanguage;
}
