import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Button, Grid, Link } from '@mui/material';
import { Box, Step, StepIconProps, StepLabel, Stepper, Typography } from '@mui/material';
import StepConnector from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

import { Constants } from '@genaios/constants/common';
import { useProgressBarInterval } from '@genaios/hooks/useProgressBarInterval';
import { IAnalyseDetectClaimsData, IAnalysePayload } from '@genaios/types';

import { useAnalyzeText } from '../AnalyzeText/AnalyzeTex.hooks';
import { LinearProgressBar } from '../LinearProgressBar/LinearProgressBar';

const steps = ['Claim Detection', 'Fact Checking'];

const CustomStepConnector = styled(StepConnector)(() => ({
  height: 0,
  visibility: 'hidden',
}));

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiStepLabel-label': {
    marginTop: theme.spacing(1),
  },
  '&.Mui-active': {
    color: theme.palette.primary.main,
    width: 50,
    height: 50,
  },
  '.MuiStepIcon-root': {
    width: 50,
    height: 50,
  },
}));

interface CustomStepIconProps {
  active: boolean;
  icon: React.ReactNode;
}

const CustomStepIcon: React.FC<CustomStepIconProps> = ({ active, icon }) => {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px solid',
        borderColor: active ? '#000' : '#000',
        backgroundColor: active ? '#C5FF96' : '#000',
        color: active ? '#000' : '#fff',
      }}>
      {icon}
    </Box>
  );
};

export const FactCheckingResults = ({ claimsData }: { claimsData: IAnalyseDetectClaimsData[] }) => {
  const { t } = useTranslation();
  const { progress } = useProgressBarInterval();

  const [claims, setClaims] = useState<IAnalyseDetectClaimsData[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [claimScanned, setClaimScanned] = useState<IAnalysePayload | null>(null);

  const StepIcon: React.FC<StepIconProps> = (props) => {
    const { active, icon } = props;
    return <CustomStepIcon active={!!active} icon={icon} />;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const { dispatchAction, factCheckingData, loading } = useAnalyzeText();

  if (!factCheckingData && !claimsData) return null;

  useEffect(() => {
    const detectedClaims: IAnalyseDetectClaimsData[] = [];

    claimsData?.forEach((item) => {
      if (item.predictions[0]?.claim) {
        detectedClaims.push(item);
      }
    });

    setClaims(detectedClaims);
  }, [claimsData]);

  const factCheckingMessage = useMemo(() => {
    if (factCheckingData && factCheckingData?.results[0]?.predictions?.label === 'True') {
      return t('scanFeatures.factCheckingSupports');
    }

    if (factCheckingData && factCheckingData?.results[0]?.predictions?.label === 'False') {
      return t('scanFeatures.factCheckingContradicts');
    }

    return t('scanFeatures.factCheckingNoSupport');
  }, [factCheckingData, t]);

  const handleStepClick = (step: number) => () => {
    setActiveStep(step);
  };

  const handleScanClaimClick = async (item: IAnalysePayload) => {
    setActiveStep(1);
    setClaimScanned(item);

    await dispatchAction([item], Constants.AnalyseOptions[1], 2);
  };

  return (
    <>
      <Box sx={{ padding: '20px', borderRadius: '8px', marginTop: '20px', overflow: 'auto', width: '100%' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Stepper activeStep={activeStep} connector={<CustomStepConnector />}>
            {steps.map((label, index) => (
              <Step key={label}>
                <CustomStepLabel
                  StepIconComponent={(props) => <StepIcon {...props} active={activeStep === index} />}
                  onClick={handleStepClick(index)}>
                  <Box sx={{ mt: 1 }}>{label}</Box>
                </CustomStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {activeStep === 0 && (
          <Box sx={{ padding: '20px', borderRadius: '8px' }}>
            <Typography sx={{ color: '#9f9f9f', textAlign: 'center', fontSize: '14px' }}>{t('scanFeatures.weWillDetect')}</Typography>

            <Box sx={{ width: '100%' }}>
              <Typography
                sx={{
                  backgroundColor: '#E4C9FF',
                  color: '#83249E',
                  padding: '20px 16px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  mt: '10px',
                  fontWeight: '700',
                }}>
                {t('scanFeatures.claimsFoundInText') + claims.length}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '10px', marginLeft: '15px' }}>
              <Typography sx={{ color: '#1F1F1F', fontSize: '16px', fontWeight: '600' }}>{t('scanFeatures.claimsDetected')}</Typography>
            </Box>

            <Box sx={{ padding: '10px', borderRadius: '8px', overflow: 'auto', width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                {claims.map((item) => (
                  <Box key={item.id} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        border: '1px solid',
                        borderRadius: '8px',
                        color: '#9F9F9F',
                        borderShadow: '2px 4px 4px 0px rgba(0, 0, 0, 0.25), -2px -4px 6px 0px rgba(0, 0, 0, 0.15)',
                        fontWeight: '400',
                        fontSize: '14px',
                        flexDirection: 'row',
                        padding: '12px',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}>
                      {item.text}
                    </Typography>
                    <Button
                      onClick={() => handleScanClaimClick({ id: +item.id, text: item.text })}
                      sx={{
                        width: '115px',
                        height: '44px',
                        fontSize: '16px',
                        fontWeight: '500',
                        border: '1px solid',
                        borderColor: '#1F1F1F',
                        padding: '13px 28px',
                        whiteSpace: 'nowrap',
                        borderRadius: '6px',
                        textTransform: 'none',
                        marginLeft: '10px',
                      }}>
                      {t('scanFeatures.scanClaim')}
                    </Button>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )}

        {activeStep === 1 && (
          <Box sx={{ padding: '20px', borderRadius: '8px' }}>
            {loading ? (
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '280px' }}>
                <LinearProgressBar variant='determinate' value={progress * 100} />

                <Typography
                  sx={{ color: '#9f9f9f', textAlign: 'center', fontSize: '14px', fontWeight: '700', lineHeight: '26px', marginTop: '15px' }}>
                  {t('scanFeatures.pleaseWait')}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ width: '100%' }}>
                <Typography sx={{ color: '#9F9F9F', textAlign: 'center', fontSize: '14px' }}>
                  {t('scanFeatures.weShowResults')}
                  <br />
                  {t('scanFeatures.youCanGoBack')}
                </Typography>

                <Button onClick={handleBack} sx={{ fontSize: '12px', fontWeight: '600', textTransform: 'capitalize' }}>
                  <KeyboardArrowLeftIcon /> {t('scanFeatures.backToClaims')}
                </Button>

                <Typography
                  sx={{
                    backgroundColor: '#E4C9FF',
                    color: '#83249E',
                    padding: '20px 16px',
                    borderRadius: '8px',
                    textAlign: 'center',
                    mt: '10px',
                    fontWeight: '700',
                  }}>
                  {factCheckingMessage || t('scanFeatures.claimsFoundInText') + claims.length}
                </Typography>

                <>
                  <Box>
                    <Typography sx={{ color: '#1F1F1F', fontSize: '16px', fontWeight: '600', marginBottom: '14px', marginTop: '7px' }}>
                      {t('scanFeatures.claimScanned')}
                    </Typography>
                    <Typography sx={{ color: '#9CA3AF', fontSize: '10px' }}>{claimScanned && claimScanned.text}</Typography>
                  </Box>

                  <Box
                    sx={{
                      border: '1px solid #DFE4EA',
                      padding: '10px',
                      borderRadius: '8px',
                      marginTop: '20px',
                      marginBottom: '10px',
                      overflow: 'auto',
                      width: '100%',
                    }}>
                    <Typography fontSize={16} fontWeight={600}>
                      {t('scanFeatures.howAiFactChecks')}
                    </Typography>

                    <Typography sx={{ color: '#9F9F9F', fontWeight: '400', fontSize: '14px' }}>
                      {t('scanFeatures.factCheckingDescription')}
                    </Typography>
                  </Box>

                  {factCheckingData && ['True', 'False']?.includes(factCheckingData?.results[0]?.predictions?.label) && (
                    <>
                      <Typography mt={3} ml='5px' mb={1} fontSize={16} fontWeight={600}>
                        {t('scanFeatures.sourcesModelDetected')}
                      </Typography>

                      <Box sx={{ padding: '5px', height: '272px', overflow: 'auto' }}>
                        {factCheckingData?.results[0]?.predictions?.sources?.map((source, index) => (
                          <Box
                            key={index}
                            sx={{
                              background: 'white',
                              boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                              borderRadius: '8px',
                              padding: '10px',
                              marginBottom: '10px',
                            }}>
                            <Typography sx={{ color: '#9F9F9F', fontWeight: '400', fontSize: '14px', mb: '5px' }}>{source?.context}</Typography>
                            <Grid display='flex' alignItems='center' gap='10px'>
                              <img src={require('assets/images/linkIcon.svg').default} alt='link' />
                              <Link href={source?.url} target='_blank' fontWeight={600} color='#000' underline='none'>
                                {t('scanFeatures.siteUrl')}
                              </Link>
                            </Grid>
                          </Box>
                        ))}
                      </Box>
                    </>
                  )}
                </>
              </Box>
            )}
          </Box>
        )}

        {activeStep === steps.length && (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={() => setActiveStep(0)}>Reset</Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </>
  );
};
