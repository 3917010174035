import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IFiltersParams, IPaginationParams, IScanHistoryState, ISortingParams } from '@genaios/types';

import * as ScanHistory from 'store/services/scan-history.service';

const initialState: IScanHistoryState = {
  scanHistoryData: undefined,
  loading: false,
};

export const getScanHistory = createAsyncThunk(
  'scanHistory/getScanHistory',
  async (
    { page, size, search, sortDirection, sortBy, typeOfDevice, typeOfScan }: IPaginationParams & ISortingParams & IFiltersParams,
    { rejectWithValue },
  ) => {
    try {
      return await ScanHistory.getScanHistory({ page, size, search, sortDirection, sortBy, typeOfDevice, typeOfScan });
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const deleteScanHistory = createAsyncThunk('scanHistory/deleteScanHistoryItem', async (id: string, { rejectWithValue }) => {
  try {
    await ScanHistory.deleteScanHistory(id);
    return id;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const renameUserResult = createAsyncThunk(
  'report/renameUserResult',
  async (payload: { resultId: string; title: string }, { rejectWithValue }) => {
    try {
      return await ScanHistory.renameUserResult(payload.resultId, payload.title);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const scanHistorySlice = createSlice({
  name: 'scanHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getScanHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getScanHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.scanHistoryData = payload;
      })
      .addCase(getScanHistory.rejected, (state, { error }) => {
        state.loading = false;
        const errorMessage = error?.message || 'Something went wrong';
        toast.error(errorMessage);
      })
      .addCase(deleteScanHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteScanHistory.fulfilled, (state, { payload: id }) => {
        state.loading = false;
        if (state.scanHistoryData) {
          state.scanHistoryData = {
            ...state.scanHistoryData,
            results: state.scanHistoryData?.results?.filter((item) => item.id !== id),
          };
          toast.success('Item deleted successfully');
        }
      })
      .addCase(deleteScanHistory.rejected, (state, { error }) => {
        state.loading = false;
        const errorMessage = error?.message || 'Failed to delete item';
        toast.error(errorMessage);
      })
      .addCase(renameUserResult.pending, (state) => {
        state.loading = true;
      })
      .addCase(renameUserResult.fulfilled, (state, { meta }) => {
        state.loading = false;
        if (state?.scanHistoryData?.results) {
          state.scanHistoryData = {
            ...state.scanHistoryData,
            results: state?.scanHistoryData?.results?.map((item) => (item?.id === meta?.arg?.resultId ? { ...item, title: meta?.arg?.title } : item)),
          };
        }
      })
      .addCase(renameUserResult.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const scanHistoryReducer = scanHistorySlice.reducer;
