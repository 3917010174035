import * as React from 'react';

interface IProps {
  color: string;
}

const FilesIconSvg = (props: IProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill={props.color} {...props}>
    <path
      fill={props.color || '#9F9F9F'}
      d='M21.262 3.263h-7.274l-.638-1.238A2.07 2.07 0 0 0 11.512.938H5.887A2.08 2.08 0 0 0 3.825 3v1.8H2.737A2.08 2.08 0 0 0 .675 6.863v14.062a2.08 2.08 0 0 0 2.062 2.063h15.375a2.08 2.08 0 0 0 2.063-2.063v-1.8h1.087a2.08 2.08 0 0 0 2.063-2.063v-11.7c.038-1.162-.9-2.1-2.063-2.1Zm-2.774 17.7a.376.376 0 0 1-.375.374H2.737a.376.376 0 0 1-.375-.375V6.9c0-.225.188-.375.375-.375h5.625c.15 0 .263.075.338.188L9.6 8.4a.9.9 0 0 0 .75.45h7.8c.225 0 .375.188.375.375v11.737h-.037Zm3.187-3.863a.376.376 0 0 1-.375.375h-1.088v-8.25a2.08 2.08 0 0 0-2.062-2.063H10.8l-.638-1.237a2.07 2.07 0 0 0-1.837-1.087H5.512v-1.8c0-.225.188-.375.375-.375h5.625c.15 0 .263.075.338.187l.9 1.65a.9.9 0 0 0 .75.45h7.8c.225 0 .375.188.375.375V17.1Z'
    />
  </svg>
);
export default FilesIconSvg;
