import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

const DocumentManager = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ textAlign: 'center' }}>
      <img src={require('assets/images/documentManager.svg').default} alt='history'></img>
      <Typography sx={{ color: '#1F1F1F', fontWeight: '700', fontSize: '30px', marginTop: '15px' }}>
        {t('documentManager.documentManager')}
      </Typography>

      <Box py={2} sx={{ background: '#F3F4F6', marginTop: '15px' }}>
        <Typography sx={{ color: '#9F9F9F', fontWeight: '400', fontSize: '20px' }}>{t('documentManager.stillBuildingSection')}</Typography>
        <Typography sx={{ color: '#9F9F9F', fontWeight: '400', fontSize: '20px' }}>{t('documentManager.stayTunedForUpdates')}</Typography>
      </Box>

      <Typography sx={{ color: '#1F1F1F', fontWeight: '400', fontSize: '18px', marginTop: '15px' }}>
        {t('documentManager.documentManagerDescription')}
      </Typography>
    </Box>
  );
};

export default DocumentManager;
