import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomHumanLinearProgress = styled(LinearProgress)(() => ({
  width: '100%',
  height: 8,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F3F4F6',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#02B83C',
  },
}));

export const HumanGeneratedCard = ({ percentage }: { percentage: string | number }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: { xs: '100%', md: '50%' },
        height: '149px',
        paddingTop: '20px',
        paddingRight: '16px',
        paddingLeft: '16px',
        borderRadius: '8px',
        bgcolor: '#DAF8E6',
        textAlign: 'center',
      }}>
      <Typography fontWeight={600} fontSize={22} sx={{ color: '#02B83C' }}>
        {percentage}%
      </Typography>
      <Typography sx={{ color: '#02B83C', marginBottom: '10px', fontSize: { xs: 14, sm: 18 } }}>
        {t('common.textWrittenBy')} <Typography fontWeight={700}>{t('common.human')}</Typography>
      </Typography>
      <CustomHumanLinearProgress variant='determinate' value={+percentage} />
    </Box>
  );
};
