import * as React from 'react';
import { useTranslation } from 'react-i18next';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import './Home.css';
import 'react-circular-progressbar/dist/styles.css';

import { TextScan } from '@genaios/components/TextScan/TextScan';
import { UploadDocument } from '@genaios/components/UploadDocument/UploadDocument';

const { useState } = React;

export const Home = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState('1');

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={1} maxWidth='md'>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ minHeight: '416px' }}>
              <TabContext value={tab}>
                <Tabs TabIndicatorProps={{ hidden: true }} value={tab} onChange={handleTabChange}>
                  <Tab
                    value='1'
                    label={t('scanFeatures.textScan')}
                    sx={{
                      '&.Mui-selected': {
                        color: '#1F1F1F',
                        fontWeight: '600',
                        backgroundColor: '#E7FFD3',
                      },
                      color: '1F1F1F',
                      borderRadius: '6px',
                      fontWeight: '600',
                      marginRight: '10px',
                      backgroundColor: '#F3F4F6',
                    }}
                  />

                  <Tab
                    value='2'
                    label={t('scanFeatures.uploadDocument')}
                    sx={{
                      '&.Mui-selected': {
                        color: '#1F1F1F',
                        fontWeight: '600',
                        backgroundColor: '#E7FFD3',
                      },
                      color: '1F1F1F',
                      borderRadius: '6px',
                      fontWeight: '600',
                      marginRight: '10px',
                      backgroundColor: '#F3F4F6',
                    }}
                  />
                </Tabs>

                <TabPanel sx={{ padding: 0 }} value='1'>
                  {tab === '1' ? <TextScan /> : <> </>}
                </TabPanel>

                <TabPanel sx={{ padding: 0 }} value='2'>
                  {tab === '2' ? <UploadDocument /> : <> </>}
                </TabPanel>
              </TabContext>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
