import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ForgotPassword } from 'pages/authentication/forgot-password/ForgotPassword';
import { ResetPassword } from 'pages/authentication/reset-password/ResetPassword';
import { SignIn } from 'pages/authentication/signin/SignIn';
import { SignUp } from 'pages/authentication/signup/SignUp';
import { VerifyAccount } from 'pages/authentication/verify-account/VerifyAccount';
import { Report } from 'pages/reports/report/Report';

export const NonAuthorizedRoutes = () => {
  return (
    <Routes>
      <Route path='/login' element={<SignIn />} />
      <Route path='/signup' element={<SignUp />} />

      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset/:token' element={<ResetPassword />} />

      <Route path='validate/*' element={<VerifyAccount />} />
      <Route path='public/reports/:model/:reportId' element={<Report />} />
      <Route path='*' element={<Navigate to='login' />} />
    </Routes>
  );
};
