import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Container, Typography } from '@mui/material';

export const EmptyScanHistory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Container component='main' maxWidth='md' sx={{ textAlign: 'center' }}>
      <img src={require('assets/images/history.svg').default} alt='history'></img>
      <Typography sx={{ color: '#1F1F1F', fontWeight: '700', fontSize: '30px', marginTop: '15px' }}>{t('scanHistory.scanHistory')}</Typography>
      <Typography sx={{ color: '#1F1F1F', fontWeight: '400', fontSize: '18px', marginTop: '15px' }}>
        {t('scanHistory.emptyScanHistoryDescription')}
      </Typography>

      <Button onClick={() => navigate('/')} variant='contained' size='large' sx={{ marginTop: '65px' }}>
        {t('scanHistory.makeFirstScan')}
      </Button>
    </Container>
  );
};
