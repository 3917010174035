import * as React from 'react';

interface IProps {
  color: string;
}
const ReportsIconSvg = (props: IProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={22} fill={props.color} {...props}>
    <path
      fill={props.color || '#9F9F9F'}
      d='M22.5 19.7h-2.025v-7.387c0-1.2-.975-2.138-2.138-2.138h-2.174c-1.2 0-2.138.975-2.138 2.137V19.7h-2.25V6.65c0-1.2-.975-2.138-2.137-2.138H7.463c-1.2 0-2.138.975-2.138 2.138V19.7H2.363V1.437A.872.872 0 0 0 1.5.575a.849.849 0 0 0-.862.862v18.338c0 .863.712 1.613 1.612 1.613H22.5c.45 0 .863-.376.863-.863s-.413-.825-.863-.825Zm-15.487 0V6.65c0-.263.225-.45.45-.45h2.175c.262 0 .45.225.45.45V19.7H7.013Zm8.7 0v-7.387c0-.263.225-.45.45-.45h2.174c.263 0 .45.225.45.45V19.7h-3.074Z'
    />
  </svg>
);
export default ReportsIconSvg;
