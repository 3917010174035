import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Button, InputAdornment, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { DeleteUserModal } from '@genaios/components/DeleteUserModal/DeleteUserModal';
import { EUserLanguage, IResetPasswordPayload, IUpdateUserPayload } from '@genaios/types';

import { deleteUser, updatePassword, updateUser, updateUserLanguage } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const AccountSettings = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { user, token } = useAppSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState<EUserLanguage>(user?.language ?? EUserLanguage.English);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: { username: user?.username || '', password: '', password_confirmation: '' },
  });

  const { control: controlAccount, handleSubmit: handleSubmitAccount } = useForm({
    defaultValues: { name: user?.firstname || '', username: user?.username || '' },
  });

  const passwordsMatch = watch('password') === watch('password_confirmation');

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const onUpdatePassword = ({ username, password, password_confirmation }: Omit<IResetPasswordPayload, 'token'>) => {
    if (!token) return;

    dispatch(updatePassword({ token, username, password, password_confirmation }))
      .unwrap()
      .then(() => {
        toast.success(t('common.updatePasswordSuccess'));
      })
      .catch();
  };

  const onUpdateNameAndEmail = ({ name, username }: Omit<IUpdateUserPayload, 'token'>) => {
    if (!token) return;

    dispatch(updateUser({ name, username, token }))
      .unwrap()
      .then(() => {
        toast.success(t('common.nameOrEmailUpdateSuccess'));
      })
      .catch();
  };

  const onLanguageChange = () => {
    if (!token) return;

    dispatch(updateUserLanguage({ language: currentSelectedLanguage, token }));
  };

  const downloadUserInfoCSV = () => {
    const csvContent = `Name,Last Name,Email\n${user?.firstname || ''},${user?.lastname || ''},${user?.username || ''}\n`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'user_info.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDeleteUser = () => {
    if (!token) return;
    dispatch(deleteUser())
      .unwrap()
      .then(() => {
        toast.success('User account has been deleted successfully!');
      })
      .catch(() => {});
  };

  return (
    <Container component='main'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', mb: '20px', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Typography
          sx={{
            color: '#1F1F1F',
            fontWeight: '600',
            fontSize: '22px',
            marginTop: '15px',
            marginBottom: '15px',
          }}>
          {t('accountSettings.accountDetails')}
        </Typography>

        <Button
          startIcon={<img src={require('assets/images/download-icon.svg').default} alt='download' />}
          component='label'
          variant='outlined'
          sx={{ width: { xs: '100%', sm: '260px' }, color: '#1F1F1F', fontWeight: 600, height: '48px' }}
          onClick={downloadUserInfoCSV}>
          {t('buttons.downloadInformation')}
        </Button>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            px='15px'
            style={{
              height: '315px',
              border: '1px solid #CFCFCF',
              borderRadius: '6px',
            }}>
            <Typography
              sx={{
                color: '#737373',
                fontWeight: '700',
                fontSize: '18px',
                paddingTop: '20px',
              }}>
              {t('accountSettings.account')}
            </Typography>
            <Typography sx={{ color: '#737373', fontWeight: '400', fontSize: '14px', paddingTop: '1px' }}>
              {t('accountSettings.accountDescription')}
            </Typography>
            <Typography
              sx={{
                color: '#737373',
                fontWeight: '500',
                fontSize: '14px',
                paddingTop: '10px',
              }}>
              {t('accountSettings.name')}
            </Typography>

            <Controller
              name='name'
              control={controlAccount}
              rules={{ required: t('accountSettings.nameFieldRequired') }}
              render={({ field }) => (
                <OutlinedInput
                  value={field.value}
                  onChange={field.onChange}
                  sx={{ height: '44px', marginTop: '5px', width: '100%' }}
                  id='outlined-adornment-weight'
                  aria-describedby='outlined-weight-helper-text'
                  placeholder={t('accountSettings.userName')}
                />
              )}
            />

            <Typography
              sx={{
                color: '#737373',
                fontWeight: '500',
                fontSize: '14px',
                paddingTop: '10px',
              }}>
              {t('accountSettings.email')}
            </Typography>

            <Controller
              name='username'
              control={controlAccount}
              rules={{ required: t('accountSettings.usernameFieldRequired') }}
              render={({ field }) => (
                <OutlinedInput
                  value={field.value}
                  readOnly
                  onChange={field.onChange}
                  disabled
                  sx={{
                    height: '44px',
                    marginTop: '5px',
                    width: '100%',
                    bgcolor: user?.isGoogle ? '#F3F4F6' : 'transparent',
                  }}
                  id='outlined-adornment-weight'
                  aria-describedby='outlined-weight-helper-text'
                  placeholder='user@example.com'
                />
              )}
            />
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' }, marginTop: '20px' }}>
              <Button component='label' variant='contained' tabIndex={-1} onClick={handleSubmitAccount(onUpdateNameAndEmail)}>
                {t('buttons.saveChanges')}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid xs={12} md={6} item>
          <Box px='15px' style={{ minHeight: '315px', border: '1px solid #CFCFCF', borderRadius: '6px' }}>
            <Typography
              sx={{
                color: '#737373',
                fontWeight: '700',
                fontSize: '18px',
                paddingTop: '20px',
              }}>
              {t('accountSettings.password')}
            </Typography>
            <Typography sx={{ color: '#737373', fontWeight: '400', fontSize: '14px', paddingTop: '1px' }}>
              {t('accountSettings.accountDescription')}
            </Typography>
            <Typography sx={{ color: '#737373', fontWeight: '500', fontSize: '14px', paddingTop: '10px' }}>
              {t('accountSettings.newPassword')}
            </Typography>

            <Controller
              name='password'
              control={control}
              rules={{ required: t('accountSettings.passwordFieldRequired') }}
              render={({ field }) => (
                <OutlinedInput
                  sx={{ height: '44px', marginTop: '5px', width: '100%' }}
                  id='outlined-adornment-new-password'
                  type={showPassword ? 'text' : 'password'}
                  value={field.value}
                  onChange={field.onChange}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} edge='end'>
                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder={t('accountSettings.newPassword')}
                />
              )}
            />

            <Typography sx={{ color: '#737373', fontWeight: '500', fontSize: '14px', paddingTop: '10px' }}>
              {t('accountSettings.confirmPassword')}
            </Typography>

            <Controller
              name='password_confirmation'
              control={control}
              rules={{ required: t('accountSettings.confirmPasswordRequired') }}
              render={({ field }) => (
                <OutlinedInput
                  sx={{ height: '44px', marginTop: '5px', width: '100%' }}
                  id='outlined-adornment-confirm-password'
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={field.value}
                  onChange={field.onChange}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle confirm password visibility' onClick={handleClickShowConfirmPassword} edge='end'>
                        {showConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder={t('accountSettings.confirmPassword')}
                />
              )}
            />
            {!passwordsMatch && (
              <Typography sx={{ color: '#f44336', fontSize: '14px', paddingTop: '5px' }}>{t('accountSettings.passwordsMatch')}</Typography>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                marginTop: '20px',
                marginBottom: '10px',
              }}>
              <Button component='label' variant='contained' tabIndex={-1} onClick={handleSubmit(onUpdatePassword)} disabled={!passwordsMatch}>
                {t('buttons.saveChanges')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <Box
            px='15px'
            style={{
              border: '1px solid #CFCFCF',
              borderRadius: '6px',
            }}>
            <Typography
              sx={{
                color: '#737373',
                fontWeight: '700',
                fontSize: '18px',
                paddingTop: '20px',
              }}>
              {t('accountSettings.language')}
            </Typography>
            <Typography sx={{ color: '#737373', fontWeight: '400', fontSize: '14px', paddingTop: '1px' }}>
              {t('accountSettings.selectLanguageDescription')}
            </Typography>

            <Select
              style={{ width: '100%', marginTop: '20px' }}
              size='small'
              value={currentSelectedLanguage}
              onChange={(e) => setCurrentSelectedLanguage(e.target.value as EUserLanguage)}>
              {Object.values(EUserLanguage).map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>

            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'flex-end' }, marginTop: '20px' }} mb={2}>
              <Button component='label' variant='contained' onClick={onLanguageChange}>
                {t('buttons.saveChanges')}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            px='15px'
            style={{
              border: '1px solid #CFCFCF',
              borderRadius: '6px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <div>
              <Typography
                sx={{
                  color: '#737373',
                  fontWeight: '700',
                  fontSize: '18px',
                  paddingTop: '20px',
                }}>
                {t('accountSettings.deleteYourAccount')}
              </Typography>
              <Typography
                sx={{
                  color: '#737373',
                  fontWeight: '400',
                  fontSize: '14px',
                  paddingTop: '1px',
                }}
                mt={2}
                mb={2}>
                {t('accountSettings.deleteAccountDescription')}
              </Typography>
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                paddingBottom: '20px',
              }}>
              <Button
                onClick={() => setShowDeleteUserModal(true)}
                sx={{
                  '&:hover': {
                    backgroundColor: '#C10606',
                    color: '#fff',
                  },
                  backgroundColor: '#C10606',
                  color: '#fff',
                  width: '190px',
                  textTransform: 'none',
                  alignItems: 'center',
                  borderRadius: '6px',
                }}>
                {t('accountSettings.deleteAccount')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DeleteUserModal open={showDeleteUserModal} onCancel={() => setShowDeleteUserModal(false)} onSave={onDeleteUser} />
    </Container>
  );
};

export default AccountSettings;
