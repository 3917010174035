import * as React from 'react';

interface IProps {
  color: string;
}
const TimeIconSvg = (props: IProps) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill={props.color} {...props}>
    <g fill={props.color || '#9F9F9F'} clipPath='url(#a)'>
      <path d='M12.563 12.375V6.338a.832.832 0 0 0-.825-.825.832.832 0 0 0-.825.825v6.375c0 .225.075.45.224.6L15.3 17.55c.15.15.375.262.6.262a.92.92 0 0 0 .6-.225.838.838 0 0 0 0-1.2l-3.938-4.012Z' />
      <path d='M12 .563C5.737.563.675 5.7.675 12S5.775 23.438 12 23.438 23.325 18.3 23.325 12C23.325 5.7 18.262.562 12 .562Zm0 21.187c-5.325 0-9.675-4.387-9.675-9.75 0-5.362 4.35-9.75 9.675-9.75 5.325 0 9.675 4.388 9.675 9.75 0 5.363-4.35 9.75-9.675 9.75Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill={props.color || '#9F9F9F'} d='M0 0h24v24H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default TimeIconSvg;
