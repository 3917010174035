import axios from 'axios';

import { Constants } from '@genaios/constants/common';

export const createAxios = (url?: string) => {
  const baseURL = url || process.env.REACT_APP_API_URL;

  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use((config) => {
    config.headers['Accept-Language'] = 'en';

    config.headers['Access-Control-Allow-Origin'] = '*';
    const token = localStorage.getItem(Constants.LocalStorage.ACCESS_TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    async (response) => {
      return response.data;
    },
    async (error) => {
      const status = error?.response?.status;
      // Handle error notifications
      return Promise.reject({ status, ...error?.response?.data });
    },
  );

  return instance;
};
