import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InsertLinkIcon from '@mui/icons-material/InsertLink';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import { Grid, Link, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { IAnalyseFactCheckingData, IDetectedClaimsConvertedResponse } from '@genaios/types';

import { useAppSelector } from 'store/hooks';

import { ReportHeader } from '../report-header/ReportHeader';

export const FactCheckingReport = () => {
  const { t } = useTranslation();

  const [parsedResult, setParsedResult] = useState<IAnalyseFactCheckingData>();
  const [parsedText, setParsedText] = useState<string[]>([]);
  const [claims, setClaims] = useState<string[]>([]);

  const { report } = useAppSelector((state) => state.report);

  const sources = useMemo(() => {
    const sources: { url: string; context: string }[] = [];

    parsedResult?.results?.forEach((item) => {
      if (['True', 'False']?.includes(item?.predictions?.label)) {
        item?.predictions?.sources?.forEach((source) => {
          sources.push(source);
        });
      }
    });

    return sources;
  }, [parsedResult]);

  const factCheckingMessage = useMemo(() => {
    if (parsedResult?.results?.[0]?.predictions?.label === 'True') {
      return t('scanFeatures.factCheckingSupports');
    }

    if (parsedResult?.results?.[0]?.predictions?.label === 'False') {
      return t('scanFeatures.factCheckingContradicts');
    }

    return t('scanFeatures.factCheckingNoSupport');
  }, [parsedResult]);

  const onNavigateToSource = () => {
    if (!report?.result?.url) return;

    window.open(report?.result?.url, '_blank');
  };

  useEffect(() => {
    if (!report?.result) return;

    if (report?.result?.model === 'CLAIM_DETECTION') {
      const claims = report?.result;
      const convertedResponse = JSON.parse(claims?.result);

      const detectedClaims: string[] = [];
      convertedResponse?.forEach((item: IDetectedClaimsConvertedResponse) => {
        const hasClaims = !!item?.predictions?.[0]?.claim?.value;

        if (hasClaims) {
          detectedClaims.push(item.text);
        }
      });
      setClaims(detectedClaims);
    }

    const parsedText = JSON.parse(report?.result?.text);
    const parsedResult: IAnalyseFactCheckingData = JSON.parse(report?.result?.result);

    setParsedResult(parsedResult);
    setParsedText(parsedText?.map((text: { text: string }) => text.text));
  }, [report]);

  return (
    <div style={{ background: 'white', width: '80%', padding: '20px', height: '100%', minHeight: '80vh' }}>
      <ReportHeader />
      <Grid mt={2} ml={2}>
        <Typography variant='subtitle1'>{t('reports.scanSource')}</Typography>
        <Typography color='#9F9F9F' variant='subtitle2'>
          {t('reports.scanningResults')}
        </Typography>
        <div onClick={() => onNavigateToSource()}>
          <Typography style={{ cursor: 'pointer' }} variant='subtitle1'>
            <InsertLinkOutlinedIcon /> {report?.report?.name}
          </Typography>
        </div>
      </Grid>
      <Box mt={2} style={{ backgroundColor: '#E4C9FF', padding: 20, borderRadius: 8 }}>
        <Typography fontWeight='bold' textAlign='center' color='#83249E'>
          {report?.report?.model === 'CLAIM_DETECTION' ? `${t('reports.claimsFound')} ${claims.length}` : factCheckingMessage}
        </Typography>
      </Box>

      <Grid mt={2}>
        {report?.report?.model === 'CLAIM_DETECTION' ? (
          <>
            <Typography fontWeight={600} fontSize={16} variant={'subtitle1'}>
              {t('reports.claimsDetected')}
            </Typography>
            {claims.map((item) => (
              <Paper style={{ padding: '20px', marginTop: '10px', color: '#9F9F9F' }} key={item} elevation={4}>
                <Typography variant='subtitle1' fontWeight='600'>
                  {`"${item}"`}
                </Typography>
              </Paper>
            ))}
          </>
        ) : (
          <Paper style={{ padding: '20px', textAlign: 'center' }}>
            <Typography variant='subtitle1' fontWeight='bold'>
              {t('scanFeatures.howAiFactChecks')}
            </Typography>
            <Typography color='#9F9F9F' variant='subtitle2'>
              {t('scanFeatures.factCheckingDescription')}
            </Typography>
          </Paper>
        )}
      </Grid>

      <Box mt={2}>
        {sources?.length > 0 ? (
          <Typography fontWeight={600} fontSize={16} variant={'subtitle1'}>
            {t('scanFeatures.sourcesModelDetected')}
          </Typography>
        ) : (
          <></>
        )}

        <Box mt={2}>
          {sources?.map((source, index) => {
            return (
              <Paper key={index} style={{ padding: '20px', marginBottom: '10px' }}>
                <Typography color='#9F9F9F' variant='subtitle2'>
                  {source?.context}
                </Typography>
                <Link
                  underline='none'
                  onClick={() => window.open(source?.url, '_blank')}
                  style={{ cursor: 'pointer', marginTop: '5px' }}
                  variant='subtitle1'
                  fontWeight='bold'>
                  <InsertLinkIcon /> {t('reports.goToSource')}
                </Link>
              </Paper>
            );
          })}
        </Box>
      </Box>

      <Box mt={2}>
        <Typography fontWeight={600} fontSize={16} variant={'subtitle1'}>
          {t('reports.scanText')}
        </Typography>
        <Box
          sx={{
            border: '1px solid #DFE4EA',
            padding: '10px',
            borderRadius: '8px',
            marginTop: '15px',
            width: '100%',
          }}>
          {parsedText?.map((result, index: number) => (
            <Typography
              key={index}
              fontWeight={400}
              fontSize={16}
              marginTop={1}
              color={'#9CA3AF'}
              sx={{
                width: '100%',
              }}>
              {result}
            </Typography>
          ))}
        </Box>
      </Box>
    </div>
  );
};
