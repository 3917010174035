import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  OutlinedInput,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { BulkActionsDropdownMenu } from '@genaios/components/BulkActionsDropdownMenu/BulkActionsDropdownMenu';
import { DeleteModal } from '@genaios/components/DeleteModal/DeleteModal';
import { SortingTableCell } from '@genaios/components/SortingTableCell/SortingTableCell';
import { TableFilters } from '@genaios/components/TableFilters/TableFilters';
import { TableLoading } from '@genaios/components/TableLoading/TableLoading';
import { IReport } from '@genaios/types';
import { toHumanReadable } from '@genaios/utils/helper';

import { deleteReport, getReports, renameReport } from 'store/features/report-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { EmptyReports } from './@components/empty-reports/EmptyReports';
import { SelectScanHistoryModal } from './@components/select-scan-history-modal/SelectScanHistoryModal';
import { ShareReport } from './@components/share-report/ShareReport';

const initialSortState = { sortKey: '', sortDirection: '' };
const initialFilterState = { typeOfScan: '', typeOfDevice: '', search: '' };

const Reports = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { reports } = useAppSelector((state) => state.report);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sorting, setSorting] = useState(initialSortState);
  const [filters, setFilters] = useState(initialFilterState);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<IReport>();
  const [showShareModal, setShowShareModal] = useState(false);
  const [bulkSelectedReports, setBulkSelectedReports] = useState<number[]>([]);
  const [hoveredCell, setHoveredCell] = useState<number | undefined>(undefined);
  const [showNameInput, setShowNameInput] = useState(false);
  const [changedName, setChangedName] = useState<string | undefined>(undefined);

  const handleMouseEnter = (id: number) => {
    if (!showNameInput) setHoveredCell(id);
  };

  const handleMouseLeave = () => {
    if (!showNameInput) setHoveredCell(undefined);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onNameChange = (reportId?: number) => {
    if (changedName === undefined) {
      return toast.warning(t('reports.sameReportName'));
    }
    if (changedName === '') {
      return toast.warning(t('reports.emptyReportName'));
    }
    if (!reportId) return;
    dispatch(
      renameReport({
        reportId,
        title: changedName,
      }),
    )
      .unwrap()
      .then(() => {
        toast.success(t('reports.renameSuccess'));
        setHoveredCell(undefined);
        setShowNameInput(false);
        setChangedName(undefined);
      })
      .catch(() => {
        toast.error(t('reports.renameError'));
      });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onOpenDeleteModal = (report: IReport) => {
    setSelectedReport(report);
    setBulkSelectedReports([]);
    setShowDeleteModal(true);
  };

  const onDeleteScanHistoryItem = () => {
    if (selectedReport?.id) {
      dispatch(deleteReport(selectedReport?.id))
        .unwrap()
        .then(() => {
          setShowDeleteModal(false);
          dispatch(getReports({ page: page + 1, size: rowsPerPage }));
        })
        .catch(() => {});
    }
  };

  const onBulkDeleteReports = () => {
    setShowDeleteModal(false);
    bulkSelectedReports.map((report, index) =>
      dispatch(deleteReport(report))
        .unwrap()
        .then(() => {
          if (bulkSelectedReports.length === index + 1) {
            dispatch(getReports({ page: page + 1, size: rowsPerPage }));
          }
        }),
    );
  };

  const onShare = (report: IReport) => {
    setSelectedReport(report);
    setShowShareModal(true);
  };

  const onReportCheck = (checked: boolean, id: number) => {
    if (checked) {
      setBulkSelectedReports((prev) => [...prev, id]);
      return;
    }
    setBulkSelectedReports((prev) => prev.filter((item) => item !== id));
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = reports?.data?.results?.map((n) => n.id) as number[];
      setBulkSelectedReports(newSelected);
      return;
    }
    setBulkSelectedReports([]);
  };

  useEffect(() => {
    dispatch(
      getReports({
        page: page + 1,
        size: rowsPerPage,
        sortDirection: sorting.sortDirection || undefined,
        sortBy: sorting.sortKey || undefined,
        search: filters.search || undefined,
        typeOfDevice: filters.typeOfDevice || undefined,
        typeOfScan: filters.typeOfScan || undefined,
      }),
    );
  }, [page, rowsPerPage, sorting, filters]);

  if (!reports?.data?.results?.length && reports.loading) {
    return (
      <Box justifyContent='center' display='flex' alignItems='center' sx={{ height: '65vh' }}>
        <CircularProgress />
      </Box>
    );
  } else if (!reports?.data?.results?.length && !filters?.typeOfScan && !filters?.typeOfDevice && !filters?.search) {
    return <EmptyReports />;
  }

  return (
    <Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
        <Typography sx={{ fontSize: '22px', fontWeight: '600', pb: '10px' }} variant='h6' id='tableTitle' component='div'>
          {t('reports.reports')}
        </Typography>

        <SelectScanHistoryModal variant='contained' title={t('reports.createAReport')} />
      </Box>

      <TableFilters
        onSaveFilters={(value) => setFilters(() => ({ search: value.documentName, typeOfDevice: value.typeOfDevice, typeOfScan: value.typeOfScan }))}
      />

      <TableContainer component={Paper} sx={{ width: '100%' }}>
        <Table sx={{ minWidth: 650 }} aria-label='caption table'>
          <TableHead sx={{ backgroundColor: '#F3F4F6', fontSize: '15px', fontWeight: '500', color: '#111928' }}>
            <TableRow>
              <TableCell padding='checkbox' style={{ width: '10%' }}>
                <Grid container alignItems={'center'} spacing={1}>
                  <Grid item>
                    <Checkbox
                      color='primary'
                      indeterminate={false}
                      checked={reports?.data?.results?.length === bulkSelectedReports.length}
                      onChange={handleSelectAllClick}
                    />
                  </Grid>
                  {bulkSelectedReports?.length > 0 ? (
                    <Grid item>
                      <BulkActionsDropdownMenu onBulkDelete={() => setShowDeleteModal(true)} />
                    </Grid>
                  ) : null}
                </Grid>
              </TableCell>
              <SortingTableCell
                title={t('scanHistory.title')}
                sortKey='title'
                activeSortKey={sorting.sortKey}
                activeSortDirection={sorting.sortDirection}
                onSort={(value) => setSorting(value)}
              />
              <SortingTableCell
                title={t('scanHistory.date')}
                sortKey='date'
                centerTitle
                activeSortKey={sorting.sortKey}
                activeSortDirection={sorting.sortDirection}
                onSort={(value) => setSorting(value)}
              />
              <SortingTableCell
                title={t('scanHistory.scan')}
                sortKey='scan'
                centerTitle
                activeSortKey={sorting.sortKey}
                activeSortDirection={sorting.sortDirection}
                onSort={(value) => setSorting(value)}
              />
              <SortingTableCell
                title={t('scanHistory.device')}
                sortKey='device'
                centerTitle
                activeSortKey={sorting.sortKey}
                activeSortDirection={sorting.sortDirection}
                onSort={(value) => setSorting(value)}
              />
              <TableCell align='right' sx={{ fontSize: '15px', fontWeight: '500', color: '#111928' }} />
            </TableRow>
          </TableHead>

          {reports?.loading ? <TableLoading /> : <></>}

          <TableBody>
            {reports?.data?.results?.map((row, index) => (
              <TableRow key={index}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    color='primary'
                    checked={bulkSelectedReports.includes(row.id)}
                    onChange={(_, checked) => onReportCheck(checked, row.id)}
                  />
                </TableCell>
                <TableCell sx={{ width: '40%' }} onMouseEnter={() => handleMouseEnter(row.id)} onMouseLeave={handleMouseLeave}>
                  {showNameInput && row.id === hoveredCell ? (
                    <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                      <Grid item>
                        <OutlinedInput defaultValue={row.name} size='small' onChange={(e) => setChangedName(e.target.value)} />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => setShowNameInput(false)}>
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => onNameChange(row?.id)}>
                          <CheckIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container direction='row' spacing={2} alignItems={'center'}>
                      <Grid item style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        <img src={require('assets/images/scan-icon.svg').default} alt='scan' style={{ paddingRight: '10px' }}></img>
                        <Tooltip title={row?.name && row?.name?.length > 30 && row?.name}>
                          <span>{row?.name && row?.name?.length < 30 ? row?.name : row?.name?.slice(0, 30) + '...'}</span>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        {row?.id === hoveredCell ? (
                          <Button
                            sx={{ fontSize: '0.8rem', textTransform: 'none', color: '#9f9f9f' }}
                            variant='text'
                            startIcon={<EditCalendarIcon />}
                            onClick={() => setShowNameInput(true)}>
                            {t('reports.editName')}
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  )}
                </TableCell>

                <TableCell align='center' sx={{ width: '15%' }}>
                  {dayjs(row?.date).format('DD/MM/YYYY')}
                </TableCell>

                <TableCell align='center' sx={{ width: '15%' }}>
                  <Box
                    sx={{
                      padding: '3px 10px',
                      backgroundColor: '#6373811A',
                      borderRadius: '30px',
                      color: '#637381',
                      fontWeight: '500',
                      fontSize: '12px',
                    }}>
                    {row?.model === 'IDENTIFICATION'
                      ? t('reports.aiScan')
                      : row?.model === 'FACT_CHECKING'
                        ? t('reports.factChecking')
                        : row?.model === 'CLAIM_DETECTION'
                          ? t('reports.claimDetection')
                          : t('reports.n/a')}
                  </Box>
                </TableCell>

                <TableCell align='center' sx={{ width: '15%' }}>
                  {toHumanReadable(row?.device) || 'N/A'}
                </TableCell>

                <TableCell scope='row' align='right'>
                  <Grid display='flex' justifyContent='end'>
                    <Button onClick={() => onShare(row)} sx={{ borderRadius: '15px', marginRight: 1 }} size='small' variant='outlined'>
                      {t('buttons.share')}
                    </Button>

                    <Button
                      onClick={() => navigate(`/reports/${row?.model?.toLowerCase()}/${row.externId}`)}
                      sx={{ borderRadius: '15px', marginRight: 1 }}
                      size='small'
                      variant='outlined'>
                      {t('buttons.view')}
                    </Button>

                    <img
                      onClick={() => onOpenDeleteModal(row)}
                      src={require('assets/images/trash-can.svg').default}
                      alt='scan'
                      style={{ cursor: 'pointer' }}></img>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {reports?.data && (
        <Box display='flex' justifyContent='flex-end'>
          <TablePagination
            className='pagination'
            rowsPerPageOptions={[5, 10, 25, 50]}
            component='div'
            count={reports?.data?.totalDocuments}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}

      {showShareModal && selectedReport && <ShareReport report={selectedReport} onCancel={() => setShowShareModal(false)} />}
      <DeleteModal
        open={showDeleteModal}
        isBulkDelete={bulkSelectedReports.length > 0}
        onCancel={() => setShowDeleteModal(false)}
        onSave={bulkSelectedReports.length > 0 ? onBulkDeleteReports : onDeleteScanHistoryItem}
      />
    </Box>
  );
};

export default Reports;
