import { createAxios } from '@genaios/config/axios';
import { IAnalyseFactCheckingData, IAnalyseTextIdentificationData } from '@genaios/types';
import { IAnalyseDetectClaimsData, IAnalysePayload } from '@genaios/types/analyze-text';

export const analyseTextIdentification = async (body: IAnalysePayload[]): Promise<{ data: IAnalyseTextIdentificationData[]; resultId: string }> =>
  createAxios().post('/v2/textIdentificationAndTypeAIBatchMultiLanguage/1', body);

export const analyseFactChecking = async (body: IAnalysePayload[]): Promise<{ data: IAnalyseFactCheckingData; resultId: string }> =>
  createAxios().post('/v2/factCheckingBatch', body);

export const claimDetect = async (body: IAnalysePayload[]): Promise<{ data: IAnalyseDetectClaimsData[]; resultId: string }> =>
  createAxios().post(`/v2/claimDetectionBatch`, body);
