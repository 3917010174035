import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SendIcon from '@mui/icons-material/Send';
import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';

import { Constants } from '@genaios/constants/common';
import { useNebulyInteractions } from '@genaios/hooks/nebuly';
import { ENebulySlug } from '@genaios/types';

export const Nebuly = ({ analyseOption, isDocumentScan = false }: { analyseOption: string; isDocumentScan?: boolean }) => {
  const { t } = useTranslation();
  const { feedbackSubmitted, onSendAction, onStartInteractionSessionWithNebuly } = useNebulyInteractions(analyseOption, isDocumentScan);

  const [selectedReaction, setSelectedReaction] = useState<ENebulySlug>();
  const [comment, setComment] = useState('');

  const onReact = (reaction: ENebulySlug) => {
    onStartInteractionSessionWithNebuly();
    setSelectedReaction(reaction);
  };

  const onSubmit = () => {
    if (!selectedReaction) return;
    onSendAction(selectedReaction, comment);
  };

  return (
    <Box>
      {feedbackSubmitted ? (
        <Grid mt={2}>
          <Typography>{t('scanFeatures.feedbackSubmittedMessage')}</Typography>
        </Grid>
      ) : (
        <Grid
          mt={3}
          container
          spacing={1}
          flexDirection={{
            xs: 'column-reverse',
            md: 'row',
          }}>
          <Grid item xs={12} md={10}>
            {selectedReaction ? (
              <>
                <Box mt={1} py={0} sx={{ border: '1px solid #9F9F9F', borderRadius: 2, padding: 1 }}>
                  <Grid>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      variant='standard'
                      placeholder={t('scanFeatures.leaveComments')}
                      value={comment}
                      onChange={(event) => setComment(event?.target?.value || '')}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                  <Grid display='flex' justifyContent='end'>
                    <Button onClick={onSubmit} disabled={!comment} endIcon={<SendIcon />}>
                      {t('buttons.send')}
                    </Button>
                  </Grid>
                </Box>

                <Box mt={2}>
                  <Typography fontSize={12} fontWeight={300} color='#9F9F9F' sx={{ mt: 1, mb: 0 }} variant='body1'>
                    {t('scanFeatures.usabilityAndResultsMessage')}
                  </Typography>
                  <Typography fontSize={12} sx={{ cursor: 'pointer' }} variant='body2' fontWeight='bold'>
                    <Link href={Constants.FeedbackForm.url} target='_blank'>
                      {t('scanFeatures.giveUsYourFeedback')}
                    </Link>
                  </Typography>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12} md={2} mt={1}>
            <Box display='flex' justifyContent='flex-end'>
              <Button onClick={() => onReact(ENebulySlug.THUMBS_UP)}>
                {selectedReaction === ENebulySlug.THUMBS_UP ? (
                  <img alt='thumbs-up' width={25} src={require('assets/images/thumbs-up-active.png')} />
                ) : (
                  <img alt='thumbs-up-active' width={25} src={require('assets/images/thumbs-up.png')} />
                )}
              </Button>
              <Button onClick={() => onReact(ENebulySlug.THUMBS_DOWN)}>
                {selectedReaction === ENebulySlug.THUMBS_DOWN ? (
                  <img alt='thumbs-down-active' width={25} src={require('assets/images/thumbs-down-active.png')} />
                ) : (
                  <img alt='thumbs-down' width={25} src={require('assets/images/thumbs-down.png')} />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
