import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PasswordField } from '@genaios/components/PasswordField/PasswordField';
import { ELoadingState, IResetPasswordPayload } from '@genaios/types';

import { resetPassword } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useParams();

  const { control, handleSubmit } = useForm({
    defaultValues: { username: '', password: '', password_confirmation: '' },
  });

  const { resetPasswordState } = useAppSelector((state) => state.auth);

  const onSubmit = ({ username, password, password_confirmation }: Omit<IResetPasswordPayload, 'token'>) => {
    if (!token) return;
    dispatch(resetPassword({ token, username, password, password_confirmation }))
      .unwrap()
      .then(() => {
        toast.success(t('authentication.passwordResetSuccessfully'));
        navigate('/login');
      })
      .catch();
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src={require('assets/images/genaios-logo-black.svg').default} width='250' height='70' alt='Genaios' />

        <Typography fontWeight='bold' sx={{ mt: 5 }} variant='h4'>
          {t('authentication.resetPassword')}
        </Typography>
        <Typography color='#667085' sx={{ mt: 1, mb: 3 }} variant='subtitle1'>
          {t('authentication.formToSetupPassword')}
        </Typography>

        <Grid sx={{ width: '100%' }}>
          <Controller
            name='username'
            control={control}
            rules={{ required: t('authentication.usernameFieldRequired') }}
            render={({ field, fieldState }) => (
              <TextField
                value={field.value}
                onChange={field.onChange}
                required
                fullWidth
                margin='normal'
                label={t('authentication.emailAddress')}
                autoComplete='email'
                autoFocus
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name='password'
            control={control}
            rules={{ required: t('authentication.passwordFieldRequired') }}
            render={({ field, fieldState }) => (
              <PasswordField
                value={field.value}
                onChange={field.onChange}
                margin='normal'
                required
                fullWidth
                label={t('authentication.password')}
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Controller
            name='password_confirmation'
            control={control}
            rules={{ required: t('authentication.passwordFieldRequired') }}
            render={({ field, fieldState }) => (
              <PasswordField
                value={field.value}
                onChange={field.onChange}
                margin='normal'
                required
                fullWidth
                label={t('authentication.repeatPassword')}
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Box>
            <Button
              fullWidth
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              disabled={resetPasswordState === ELoadingState.LOADING}
              sx={{ mt: 2, backgroundColor: 'black', borderRadius: 2 }}>
              {resetPasswordState === ELoadingState.LOADING ? t('buttons.resetting') : t('buttons.resetPassword')}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Container>
  );
};
