import { createAxios } from '@genaios/config/axios';
import { IReport, IReports, ISingleReport } from '@genaios/types';

export const getReports = async (
  page = 1,
  size = 10,
  sortBy?: string,
  sortDirection?: string,
  search?: string,
  typeOfDevice?: string,
  typeOfScan?: string,
): Promise<IReports> => createAxios().get(`/v2/reports`, { params: { page, size, sortBy, sortDirection, search, typeOfDevice, typeOfScan } });

export const getReportById = async (reportId: string): Promise<ISingleReport> => createAxios().get(`/public/reports/${reportId}`);

export const createReport = async (body: { scanHistoryId: string }): Promise<IReport> => createAxios().post(`/v2/reports`, body);

export const deleteReport = async (reportId: number): Promise<void> => createAxios().delete(`/v2/reports/${reportId}`);

export const renameReport = async (reportId: number, title: string) => createAxios().post(`/v2/renameReport/${reportId}`, { name: title });
