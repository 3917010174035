import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';

import { IPrediction } from '@genaios/types/';
import { isHumanGenerated } from '@genaios/utils/helper';

interface IProps {
  result: {
    infoText: string;
    color?: string;
    generatedPercent?: number | undefined;
    text?: string | undefined;
    predictions?: IPrediction[];
    modelFamily: { label: string; value: number };
  };
}

export const ScannedResultDetails = ({ result }: IProps) => {
  const { t } = useTranslation();
  const isHuman = isHumanGenerated(result);

  return (
    <>
      <Box
        sx={{
          pt: '16px',
          textAlign: 'center',
        }}>
        <Box
          sx={{
            border: `1px solid ${isHuman ? '#22AD5C' : '#F23030'}`,
            borderRadius: '30px',
            width: '100%',
            minHeight: '26px',
            padding: { xs: '5px', sm: 0 },
            ml: 'auto',
            mr: 'auto',
            mb: '10px',
          }}>
          <Typography sx={{ color: isHuman ? '#22AD5C' : '#F23030', fontSize: '12px', fontWeight: 600, mt: '3px' }}>
            {isHuman ? t('common.writtenByHuman') : t('common.writtenByAi')}
          </Typography>
        </Box>

        <Typography sx={{ color: '#1F1F1F', fontSize: '12px', fontWeight: 600, mb: '10px' }}>{t('common.aiProbabilities')}</Typography>
        <Typography sx={{ color: '#9F9F9F', fontSize: '12px', fontWeight: 400, mb: '5px' }}>
          {isHuman ? t('common.largeLanguageModelsByHuman') : t('common.largeLanguageModelsDetectedText')}
        </Typography>
        {!isHuman && <Typography sx={{ color: '#1F1F1F', fontSize: '16px', fontWeight: 700 }}>{result?.modelFamily?.label}</Typography>}
      </Box>
    </>
  );
};
