import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IAnalyzeDocumentState, IProcessDocumentPayload } from '@genaios/types';

import * as AnalyzeDocument from 'store/services/analyze-document.service';

const initialState: IAnalyzeDocumentState = {
  loading: false,
  showScanDetails: false,
  identificationData: undefined,
  claimsData: undefined,
  resultId: '',
  uploadPercentage: 0,

  nebulyAnalyseReport: {
    payload: undefined,
    response: undefined,
  },
};

export const processDocument = createAsyncThunk('analyzeDocument/processDocument', async (params: IProcessDocumentPayload, { rejectWithValue }) => {
  try {
    const { document, model, page } = params;
    return await AnalyzeDocument.processDocument({
      document,
      model,
      page,
    });
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const analyzeDocumentSlice = createSlice({
  name: 'analyzeDocument',
  initialState,
  reducers: {
    resetDocument: (state) => {
      state.identificationData = undefined;
      state.claimsData = undefined;
      state.resultId = '';
      state.uploadPercentage = 0;

      state.nebulyAnalyseReport = undefined;
    },
    setShowScanDetails(state, action) {
      state.showScanDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processDocument.pending, (state) => {
        state.uploadPercentage = 0;
        state.loading = true;
        analyzeDocumentSlice.caseReducers.resetDocument(state);
      })
      .addCase(processDocument.fulfilled, (state, { payload }) => {
        state.uploadPercentage = 100;
        state.loading = false;

        state.nebulyAnalyseReport = {
          payload: payload?.body.toString(),
          response: payload?.body.toString(),
        };

        const modelType = payload.headers.model[0];

        try {
          const body = typeof payload.body === 'string' ? JSON.parse(payload.body) : payload.body;
          state.resultId = body.resultId;

          if (modelType === 'IDENTIFICATION') {
            state.identificationData = body.data;
          }

          if (modelType === 'CLAIM_DETECTION') {
            state.claimsData = body.data;
          }
        } catch {
          return;
        }
      })
      .addCase(processDocument.rejected, (state) => {
        state.loading = false;
        state.uploadPercentage = 0;
        toast.error('Something went wrong');
      });
  },
});

export const { resetDocument, setShowScanDetails } = analyzeDocumentSlice.actions;

export const analyzeDocumentReducer = analyzeDocumentSlice.reducer;
