import { createAxios } from '@genaios/config/axios';
import { ILoginPayload, ILoginResponse, IRegisterPayload, IResetPasswordPayload, IUpdateUserPayload, IUser } from '@genaios/types';

export const login = async (body: ILoginPayload): Promise<ILoginResponse> => createAxios(process.env.REACT_APP_API_AUTH_URL).post('/login', body);

export const register = async (body: IRegisterPayload): Promise<void | ILoginResponse> =>
  createAxios(process.env.REACT_APP_API_AUTH_URL).post('/register', body);

export const forgotPassword = async (body: { username: string }): Promise<boolean> =>
  createAxios(process.env.REACT_APP_API_AUTH_URL).post('/sendResetPassword', body);

export const resetPassword = async (body: IResetPasswordPayload): Promise<boolean> =>
  createAxios(process.env.REACT_APP_API_AUTH_URL).post('/resetPassword', body);

export const updatePassword = async (body: IResetPasswordPayload): Promise<boolean> =>
  createAxios(process.env.REACT_APP_API_AUTH_URL).post('/updatePassword', body);

export const updateUser = async (body: IUpdateUserPayload): Promise<boolean> =>
  createAxios(process.env.REACT_APP_API_AUTH_URL).post('/updateUser', body);

export const isTokenValid = async (): Promise<boolean> => createAxios(process.env.REACT_APP_AUTH_SEC_URL).get('/isTokenValid');

export const isValidCounterUser = async (token: string): Promise<boolean> =>
  createAxios(process.env.REACT_APP_API_AUTH_URL).get('/validateCountUser', { params: { token } });

export const getUserByToken = async (token: string): Promise<IUser> =>
  createAxios(process.env.REACT_APP_API_AUTH_URL).get('/userByToken', { params: { token } });

export const deleteUser = async () => createAxios().delete('/v2/deleteUser');

export const updateUserLanguage = async (body: { language: string; token: string }) =>
  createAxios(process.env.REACT_APP_API_AUTH_URL).post('/updateLanguage', body);
