import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LinearProgressBar = styled(LinearProgress)(() => ({
  width: '470px',
  height: 8,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#F2F4F7',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#1F1F1F',
  },
}));
