import { useEffect, useState } from 'react';

const progressTime = 120;
const progressTarget = Math.random() * (2.5 - 1.4) + 1.4;

export const useProgressBarInterval = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const progressIncrementPerSecond = progressTarget / progressTime;

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < progressTarget && prevProgress < 1.4) {
          return prevProgress + progressIncrementPerSecond;
        } else {
          clearInterval(interval);
          return prevProgress;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return { progress };
};
