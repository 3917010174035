import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';

import { Constants } from '@genaios/constants/common';
import { IPrediction, ModelObject } from '@genaios/types/';
import { getResultAccordingPercentage, isHumanGenerated } from '@genaios/utils/helper';

import { HighlightedTextTooltip } from '../CustomTooltip/CustomTooltip';
import { AIGeneratedCard } from '../GeneratedResultsCards/AIGeneratedCard';
import { HumanGeneratedCard } from '../GeneratedResultsCards/HumanGeneratedCard';
import { ScannedResultDetails } from '../ScannedResultDetails/ScannedResultDetails';
import { ViewScanDetailsButton } from '../ViewScanDetailsButton/ViewScanDetailsButton';
const { useState, useEffect, useMemo } = React;

interface IResultData {
  infoText: string;
  color?: string;
  bgColor?: string;
  modelFamily: { label: string; value: number };
  description?: string;
  generatedPercent?: number | undefined;
  text?: string | undefined;
  predictions?: IPrediction[];
}

interface IProps {
  resultsData:
    | {
        predictions: IPrediction[];
        attributes: ModelObject[];
        text: string;
      }[]
    | undefined;
}

export const AIContentResults = ({ resultsData }: IProps) => {
  const { t } = useTranslation();

  const [showScanDetails, setShowScanDetails] = useState(false);
  const [expandedResults, setExpandedResults] = useState<IResultData[] | undefined>(undefined);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<React.ReactNode>(null);

  const handleMouseEnter = (result: IResultData) => {
    setTooltipContent(<ScannedResultDetails result={result} />);
    setTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };

  const generatedProperties = useMemo(() => {
    if (!resultsData) return { human: 0, ai: 0 };
    const total = resultsData?.length;
    let human = 0;

    resultsData?.forEach((result) => {
      if (isHumanGenerated(result)) {
        human++;
      }
    });

    const humanGenerated = (human / total) * 100;
    return { human: humanGenerated?.toFixed(2), ai: (100 - humanGenerated)?.toFixed(2) };
  }, [resultsData]);

  useEffect(() => {
    if (resultsData?.length) {
      const newResultsData: IResultData[] = [];

      resultsData?.map((item) => {
        return { ...item, ...getResultAccordingPercentage(item?.predictions[0]?.generated?.value) };
      });

      resultsData?.forEach((result) => {
        let modelFamily = { label: '', value: 0 };

        result?.attributes?.forEach((attribute) => {
          Object.keys(attribute).forEach((key) => {
            const model = attribute[key];
            if (model?.value > modelFamily?.value) {
              modelFamily = { label: model?.label, value: model?.value };
            }
          });
        });

        newResultsData.push({ ...result, ...getResultAccordingPercentage(result?.predictions[0]?.generated?.value), modelFamily });
      });

      setExpandedResults(newResultsData);
    }
  }, [resultsData]);

  useEffect(() => {
    if (expandedResults?.length === 1) {
      handleMouseEnter(expandedResults[0]);
    }
  }, [expandedResults]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: '20px', width: '100%', flexDirection: { xs: 'column', md: 'row' } }}>
        <HumanGeneratedCard percentage={generatedProperties?.human} />
        <AIGeneratedCard percentage={generatedProperties?.ai} />
      </Box>

      <Box sx={{ width: '100%' }}>
        {showScanDetails && (
          <>
            <Box
              sx={{
                mt: '30px',
                width: '100%',
                height: '158px',
                border: '1px solid #CFCFCF',
                borderRadius: '8px',
                textAlign: 'center',
                position: 'relative',
              }}>
              <Typography sx={{ color: '#1F1F1F', fontSize: '12px', fontWeight: 600, mt: '15px' }}>{t('common.hoverForDetails')}</Typography>

              <Typography sx={{ color: '#9F9F9F', fontSize: '12px', fontWeight: 400 }}>{t('common.highlightedText')}</Typography>

              <HighlightedTextTooltip open={tooltipOpen} content={tooltipContent} />
            </Box>

            {expandedResults && (
              <Box
                sx={{
                  border: '1px solid #DFE4EA',
                  padding: '10px',
                  borderRadius: '8px',
                  marginTop: '15px',
                  height: '232px',
                  overflow: 'auto',
                  width: '100%',
                }}>
                {expandedResults?.map((result, index) => (
                  <Typography
                    key={index}
                    fontWeight={400}
                    fontSize={16}
                    marginTop={1}
                    color={'#9CA3AF'}
                    onMouseEnter={() => {
                      const generatedPercent = result?.predictions && result?.predictions?.[0]?.generated?.value;

                      if (
                        (generatedPercent &&
                          generatedPercent * 100 > Constants.Analyze.GeneratedAILowerThreshold &&
                          generatedPercent * 100 <= Constants.Analyze.GeneratedAIUpperThreshold) ||
                        expandedResults.length === 1
                      ) {
                        return null;
                      }

                      return handleMouseEnter(result);
                    }}
                    onMouseLeave={expandedResults.length === 1 ? () => null : handleMouseLeave}
                    sx={{
                      width: '100%',
                      backgroundColor: result?.bgColor,
                    }}>
                    {result.text}
                  </Typography>
                ))}
              </Box>
            )}
          </>
        )}

        <ViewScanDetailsButton showScanDetails={showScanDetails} setShowScanDetails={setShowScanDetails} />
      </Box>
    </>
  );
};
