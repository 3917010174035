import React from 'react';

import { Box } from '@mui/material';

interface CustomTooltipProps {
  open: boolean;
  content: React.ReactNode;
}

export const HighlightedTextTooltip: React.FC<CustomTooltipProps> = ({ open, content }) => {
  return (
    <Box
      sx={{
        display: open ? 'block' : 'none',
        position: 'absolute',
        top: '-3px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: 1,
        width: '100%',
        minHeight: 158,
        borderRadius: '5px',
        zIndex: 9999,
        padding: '10px',
      }}>
      {content}
    </Box>
  );
};
