import * as React from 'react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PasswordField } from '@genaios/components/PasswordField/PasswordField';
import { Constants } from '@genaios/constants/common';
import { ELoadingState, IRegisterPayload } from '@genaios/types';

import { logout } from 'store/features/auth-slice';
import { register } from 'store/features/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const SignUp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState('');
  const [privacyPolicy, setPrivacyPolicy] = useState({
    checked: false,
    displayError: false,
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      username: '',
      firstname: '',
      lastname: '',
      password: '',
      conf_password: '',
      country: 'Spain',
      rememberMe: false,
      subscription: 0,
    },
  });

  const { registerState } = useAppSelector((state) => state.auth);

  const onSubmit = (values: IRegisterPayload) => {
    if (!privacyPolicy.checked) {
      setPrivacyPolicy((prev) => ({ ...prev, displayError: true }));
      return toast.error(
        <>
          <Typography variant='h6'>{t('authentication.privacyPolitics')}</Typography>
          <Typography paragraph>{t('authentication.acceptPrivacyPolitics')}</Typography>
        </>,
        { style: { background: '#d9b4b8', color: 'black' }, icon: <RemoveCircleIcon style={{ color: 'red', transform: 'rotate(45deg)' }} /> },
      );
    }
    if (values.password === values.conf_password) {
      dispatch(register(values))
        .unwrap()
        .then(() => {
          dispatch(logout());
          navigate('/login');
          toast.success(t('authentication.accountCreateSuccess'));
        })
        .catch((error) => {
          if (error?.status === 409) {
            setErrorMessage(t('authentication.alreadyHaveAccount'));
          }
        });
    } else {
      toast.error(t('authentication.passwordsNotSame'));
    }
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 16,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img src={require('assets/images/genaios-logo-black.svg').default} width='250' height='70' alt='Genaios' />

        <Typography fontWeight='bold' sx={{ mt: 5 }} variant='h4'>
          {t('authentication.createAccount')}
        </Typography>

        <Typography
          onClick={() => {
            navigate('/forgot-password');
          }}
          textAlign='center'
          color='#667085'
          sx={{ mt: 1, mb: 3, color: errorMessage ? 'red' : undefined, cursor: errorMessage ? 'pointer' : undefined }}
          variant='subtitle1'>
          {errorMessage ? errorMessage : t('authentication.fillCreateAccountForm')}
        </Typography>

        <Box component='div'>
          <Controller
            name='username'
            control={control}
            rules={{ required: t('authentication.emailFieldRequired') }}
            render={({ field, fieldState }) => (
              <TextField
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
                margin='normal'
                required
                fullWidth
                label={t('authentication.emailAddress')}
                autoComplete='email'
                autoFocus
              />
            )}
          />

          <Controller
            name='firstname'
            control={control}
            rules={{ required: t('authentication.firstNameRequired') }}
            render={({ field, fieldState }) => (
              <TextField
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
                margin='normal'
                required
                fullWidth
                label={t('authentication.firstName')}
                autoComplete='name'
              />
            )}
          />

          <Controller
            name='lastname'
            control={control}
            rules={{ required: t('authentication.lastNameRequired') }}
            render={({ field, fieldState }) => (
              <TextField
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
                margin='normal'
                required
                fullWidth
                label={t('authentication.lastName')}
                autoComplete='lastName'
              />
            )}
          />

          <Controller
            name='password'
            control={control}
            rules={{ required: t('authentication.passwordFieldRequired') }}
            render={({ field, fieldState }) => (
              <PasswordField
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
                margin='normal'
                required
                fullWidth
                label={t('authentication.password')}
                autoComplete='current-password'
              />
            )}
          />

          <Controller
            name='conf_password'
            control={control}
            rules={{ required: t('authentication.confirmPasswordFieldRequired') }}
            render={({ field, fieldState }) => (
              <PasswordField
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState?.error}
                helperText={fieldState.error?.message}
                margin='normal'
                required
                fullWidth
                name='password'
                label={t('authentication.confirmPassword')}
                id='conf_password'
                autoComplete='current-password'
              />
            )}
          />

          <Grid container alignItems='center'>
            <Grid xs>
              <Controller
                name='rememberMe'
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    value={field.value}
                    onChange={field.onChange}
                    control={<Checkbox value='remember' color='primary' />}
                    label={t('authentication.rememberMe')}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container alignItems='center'>
            <Grid xs>
              <FormControlLabel
                control={<Checkbox style={{ color: privacyPolicy.displayError ? 'red' : '' }} />}
                onChange={(event: React.SyntheticEvent<Element, Event>, checked: boolean) => setPrivacyPolicy({ checked, displayError: false })}
                label={
                  <span style={{ color: privacyPolicy.displayError ? 'red' : '', display: 'flex' }}>
                    {t('authentication.readAndAcknowledge')}{' '}
                    <Typography
                      sx={{ textDecoration: 'underline', marginLeft: '3px' }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(Constants.PrivacyPolicy.url, '_blank');
                      }}>
                      {t('authentication.privacyPolicy')}
                    </Typography>
                  </span>
                }
              />
            </Grid>
          </Grid>

          <Grid container alignItems='center'>
            <Controller
              name='subscription'
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ alignItems: 'baseline' }}
                  control={
                    <Checkbox
                      checked={field.value === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0;
                        setValue('subscription', newValue);
                      }}
                      color='primary'
                    />
                  }
                  label={t('authentication.receiveInformation')}
                />
              )}
            />
          </Grid>

          <Box>
            <Button
              fullWidth
              disabled={registerState === ELoadingState.LOADING}
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              sx={{ mt: 3, backgroundColor: 'black', borderRadius: 2 }}>
              {registerState === ELoadingState.LOADING ? t('authentication.creating') : t('authentication.createNewAccount')}
            </Button>
          </Box>

          <Grid container alignItems='center' mt={1} justifyContent='center'>
            <Typography color='#667085' flexDirection='row' variant='subtitle1'>
              {t('authentication.alreadyHaveAnAccount')}
            </Typography>
            <Typography onClick={() => navigate('/signin')} sx={{ ml: 1, cursor: 'pointer' }} variant='subtitle1' fontWeight='bold'>
              {t('authentication.login')}
            </Typography>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
