import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import { grey } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { MainRouter } from '@genaios/routes/MainRouter';

import i18n from './translation/i18n';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: '#white',
          borderColor: '#1F1F1F',
          backgroundColor: '#111928',
          textTransform: 'none',

          '&:hover': {
            color: '#111928',
            borderColor: '#1F1F1F',
            backgroundColor: '#CFCFCF',
          },
        },
        outlined: {
          color: '#1F1F1F',
          borderColor: '#1F1F1F',
          textTransform: 'none',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent', // Set the background color to transparent
        },
      },
    },
  },
  palette: {
    primary: {
      light: grey[900],
      main: grey[900],
      dark: grey[900],
    },
    secondary: {
      main: '#1f1f1f',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <MainRouter />
        <ToastContainer />
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
