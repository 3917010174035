import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import { analyzeDocumentReducer } from './features/analyze-document-slice';
import { analyzeTextReducer } from './features/analyze-text-slice';
import { authReducer } from './features/auth-slice';
import { commonReducer } from './features/common-slice';
import { reportReducer } from './features/report-slice';
import { scanHistoryReducer } from './features/scan-history-slice';

export const defaultReducers = {
  common: commonReducer,
  auth: authReducer,
  analyzeText: analyzeTextReducer,
  analyzeDocument: analyzeDocumentReducer,
  scanHistory: scanHistoryReducer,
  report: reportReducer,
};
const rootReducer = combineReducers(defaultReducers);

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    preloadedState,
    reducer: rootReducer,
  });

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export default store;
